export default new class {
	
	isSupported = () => window.caches !== undefined;
	
	get = (cacheName) => new Promise(async (resolve, reject) => {
		if(window.caches !== undefined) {
			const cache = await window.caches.open(cacheName).catch(reject);
			
			if(!(cache instanceof Cache)) return reject(new Error('Could not find the cache'));
			
			const files = await cache.keys().then((keys) => keys.map(({ url }) => url)).catch(reject);
			
			return resolve({cache, files: files instanceof Array ? files : []});
		}
		
		reject(new Error('Caches are not supported'));
	});
	
	getResponse = (url) => new Promise(async (resolve, reject) => {
		if(window.caches !== undefined) {
			const response = await window.caches.match(url).catch(reject);
			
			return response instanceof Response ? resolve(response) : reject(new Error('Could not find the file'));
		}
		
		reject(new Error('Caches are not supported'));
	});
	
	getBase64 = (url) => new Promise(async (resolve, reject) => {
		const response = await this.getResponse(url).catch(reject);
		
		if(!(response instanceof Response)) return reject(new Error('Could not find the response'));
		
		const buffer = await response.arrayBuffer().catch(reject);
		
		if(!(buffer instanceof ArrayBuffer)) return reject(new Error('Could not find the file'));
		
		const base64 = btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
		
		resolve('data:' + response.headers.get('Content-Type') + ';base64,' + base64);
	});
	
}
<template>
	<section class="content-section content-wrapper">
		<h1 class="section-header">
			<p class="text">Навигация по техникуму</p>
		</h1>
		
		<vue-select class="select-large" :value="$route.query.room" :options="__rooms.map(({ id: value, title: text, description }) => ({value, text, description}))"
			placeholder="Поиск по кабинетам" @value="(value) => $router.push({query: {...$route.query, room: value === null ? undefined : value}})">
			
			<template #primary-left-content>
				<svg class="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
					<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
				</svg>
			</template>
		</vue-select>
	</section>
	
	<section v-for="category, i in new Set(_maps.map(({ category }) => category))" :key="i" class="content-section content-wrapper">
		<h2 class="section-header">
			<p class="text">{{ category }}</p>
		</h2>
		
		<div class="maps-list">
			<div v-for="{ id, title, category, ...map }, i in _maps.filter((map) => map.category === category)" :key="i" class="list-item">
				<vue-map v-bind="map" :room="$route.query.room" @select="(room) => $router.push({query: {...$route.query, room}})" />
				
				<p class="text">{{ title }}</p>
			</div>
		</div>
	</section>
	
	<vue-modal v-if="__room !== null" :title="__room.title" @close="$router.push({query: {...$route.query, room: undefined}})">
		<div class="room-information">
			<div v-if="__room.details !== undefined && __room.details.length > 0"  class="room-block">
				<p class="room-block-title">Информация о кабинете:</p>
				
				<ul class="room-details">
					<li v-for="{ name, text }, i in __room.details" :key="i" class="room-detail"><span class="detail-name">{{ name }}</span>: <span class="detail-text">{{ text }}</span></li>
				</ul>
			</div>
			
			<div v-if="__room.persons !== undefined && __room.persons.length > 0" class="room-block">
				<p class="room-block-title">Персонал кабинета:</p>
				
				<ul class="room-details">
					<li v-for="{ role, name }, i in __room.persons" :key="i" class="room-detail"><span class="detail-name">{{ name }}</span> – <span class="detail-text">{{ role }}</span></li>
				</ul>
			</div>
			
			<div class="room-maps">
				<div v-for="{ title, category, ...map }, i in _maps.filter(({ rooms }) => rooms.find(({ id }) => id === __room.id))" :key="i" class="room-map">
					<vue-map v-bind="map" :room="__room.id" :readonly="true" />
					
					<p class="text">{{ category }} &nbsp;►&nbsp; {{ title }}</p>
				</div>
			</div>
		</div>
	</vue-modal>
</template>

<script>
	import VueSelect from '@/components/VueSelect';
	import VueMap from '@/components/VueMap';
	import VueModal from '@/components/VueModal';
	
	export default {
		name: 'BuildingView',
		
		components: {VueSelect, VueMap, VueModal},
		
		computed: {
			__rooms() {
				return [].concat.apply([], this._maps.map(({ rooms }) => rooms)).filter(({ title }) => title !== undefined)
					.sort(({ title: a = '' }, { title: b = '' }) => a > b ? 1 : a < b ? -1 : 0);
			},
			
			__room() {
				return this.__rooms.find(({ id }) => id === this.$route.query.room) ?? null;
			}
		},
		
		data() {
			return {
				_maps: [
					{
						category: 'Учебный корпус',
						
						title: 'Первый этаж',
						
						width: 2200,
						height: 400,
						
						rooms: [
							{
								id: 'wuMDTf',
								
								bounds: [{x: 400, y: 200, width: 1400, height: 100}, {x: 700, y: 300, width: 600, height: 100}]
							},
							{
								id: 'PtKxLI',
								
								title: '29 кабинет',
								
								bounds: [{x: 0, y: 200, width: 400, height: 200}],
								
								labels: [{x: 200, y: 300, text: '№29'}],
								
								persons: [{role: 'Преподаватель', name: 'Шершнёв Андрей Юрьевич'}]
							},
							{
								id: 'T9WrSh',
								
								parent: 'yRWeaX',
								
								bounds: [{x: 0, y: 0, width: 100, height: 200}]
							},
							{
								id: 'yRWeaX',
								
								title: '30 кабинет',
								
								bounds: [{x: 100, y: 0, width: 400, height: 200}],
								
								labels: [{x: 300, y: 100, text: '№30'}],
								
								persons: [{role: 'Преподаватель', name: 'Кохан Виталий Владимирович'}]
							},
							{
								id: 'zDmTDD',
								
								title: 'Психолог',
								
								bounds: [{x: 500, y: 0, width: 200, height: 100}, {x: 500, y: 100, width: 100, height: 100}],
								
								labels: [{x: 550, y: 50, text: 'П'}],
								
								persons: [{role: 'Педагог-психолог', name: 'Лошакова Людмила Алексеевна'}]
							},
							{
								id: 'NjeOKD',
								
								title: 'Женский туалет',
								
								bounds: [{x: 600, y: 100, width: 100, height: 100}],
								
								labels: [{x: 650, y: 150, text: 'Ж'}]
							},
							{
								id: 'rXwPee',
								
								title: '33 кабинет',
								
								bounds: [{x: 700, y: 0, width: 400, height: 200}],
								
								labels: [{x: 900, y: 100, text: '№33'}],
								
								persons: [{role: 'Преподаватель', name: 'Закревская Рена Новрузовна'}]
							},
							{
								id: 'LPebXN',
								
								title: '34 кабинет',
								
								bounds: [{x: 1100, y: 0, width: 400, height: 200}],
								
								labels: [{x: 1300, y: 100, text: '№34'}],
								
								persons: [{role: 'Преподаватель', name: 'Меркачева Людмила Ивановна'}]
							},
							{
								id: 'LNvrIK',
								
								title: 'Мужской туалет',
								
								bounds: [{x: 1500, y: 0, width: 200, height: 100}, {x: 1500, y: 100, width: 100, height: 100}],
								
								labels: [{x: 1550, y: 50, text: 'М'}]
							},
							{
								id: 'LSMg5b',
								
								bounds: [{x: 1600, y: 100, width: 100, height: 100}]
							},
							{
								id: 'KEjrbd',
								
								title: 'Библиотека',
								
								bounds: [{x: 1700, y: 0, width: 500, height: 200}],
								
								labels: [{x: 1950, y: 100, text: 'БИБЛИОТЕКА', size: 50}],
								
								persons: [{role: 'Библиотекарь', name: 'Малышева Татьяна Петровна'}]
							},
							{
								id: 'XLQKpV',
								
								title: '37 кабинет',
								
								bounds: [{x: 1800, y: 200, width: 400, height: 200}],
								
								labels: [{x: 2000, y: 300, text: '№37'}],
								
								persons: [{role: 'Преподаватель', name: 'Матькин Никита Сергеевич'}]
							},
							{
								id: 'at2FY4',
								
								bounds: [{x: 400, y: 300, width: 300, height: 100}]
							},
							{
								id: 'mzn8cP',
								
								bounds: [{x: 1500, y: 300, width: 300, height: 100}]
							},
							{
								id: 'BXHBMU',
								
								bounds: [{x: 1300, y: 300, width: 200, height: 100}]
							}
						],
						
						doors: [
							{x: 100, y: 150, rotate: 180, rooms: ['T9WrSh', 'yRWeaX']},
							{x: 400, y: 250, rotate: 180, rooms: ['wuMDTf', 'PtKxLI']},
							{x: 700, y: 350, rotate: 180, rooms: ['wuMDTf', 'at2FY4']},
							{x: 1800, y: 250, rotate: 180, rooms: ['wuMDTf', 'XLQKpV']},
							{x: 1500, y: 350, rotate: 180, rooms: ['mzn8cP', 'BXHBMU']},
							
							{x: 450, y: 200, rooms: ['wuMDTf', 'yRWeaX']},
							{x: 550, y: 200, rooms: ['wuMDTf', 'zDmTDD']},
							{x: 650, y: 200, rooms: ['wuMDTf', 'NjeOKD']},
							{x: 1050, y: 200, rooms: ['wuMDTf', 'rXwPee']},
							{x: 1450, y: 200, rooms: ['wuMDTf', 'LPebXN']},
							{x: 1550, y: 200, rooms: ['wuMDTf', 'LNvrIK']},
							{x: 1650, y: 200, rooms: ['wuMDTf', 'LSMg5b']},
							{x: 1750, y: 200, rooms: ['wuMDTf', 'KEjrbd']},
							{x: 1550, y: 300, rooms: ['wuMDTf', 'mzn8cP']}
						]
					},
					{
						category: 'Учебный корпус',
						
						title: 'Второй этаж',
						
						width: 2200,
						height: 400,
						
						rooms: [
							{
								id: 'Ykc8AK',
								
								bounds: [{x: 400, y: 200, width: 1400, height: 100}, {x: 700, y: 300, width: 800, height: 100}]
							},
							{
								id: 'p7BD9S',
								
								parent: 'SX9WpS',
								
								bounds: [{x: 0, y: 0, width: 100, height: 200}]
							},
							{
								id: 'SX9WpS',
								
								title: '57 кабинет',
								
								bounds: [{x: 0, y: 200, width: 400, height: 200}],
								
								labels: [{x: 200, y: 300, text: '№57'}],
								
								persons: [{role: 'Преподаватель', name: 'Малиновская Ольга Юрьевна'}]
							},
							{
								id: 'nwSRqU',
								
								title: '55 кабинет',
								
								bounds: [{x: 100, y: 0, width: 400, height: 200}],
								
								labels: [{x: 300, y: 100, text: '№55'}],
								
								persons: [{role: 'Преподаватель', name: 'Дюжова Ирина Владимировна'}]
							},
							{
								id: 'NWsjMT',
								
								title: 'Отдел кадров',
								
								bounds: [{x: 500, y: 0, width: 200, height: 100}, {x: 500, y: 100, width: 100, height: 100}],
								
								labels: [{x: 550, y: 100, text: 'ОК', rotate: -180}],
								
								persons: [{role: 'Специалист отдела кадров', name: 'Аверина Екатерина Васильевна'}]
							},
							{
								id: 'UgTyHR',
								
								bounds: [{x: 600, y: 100, width: 100, height: 100}]
							},
							{
								id: 'PKJ6jj',
								
								title: 'Бухгалтерия',
								
								bounds: [{x: 700, y: 0, width: 133, height: 200}],
								
								labels: [{x: 766.5, y: 100, text: 'БУХ.', rotate: -180}],
								
								persons: [{role: 'Бухгалтер', name: 'Гаврилова Ольга Викторовна'}, {role: 'Бухгалтер', name: 'Бойцова Елена Германовна'}]
							},
							{
								id: 'q4CfFQ',
								
								title: 'Главный бухгалтер',
								
								bounds: [{x: 833, y: 0, width: 134, height: 200}],
								
								labels: [{x: 900, y: 100, text: 'ГБ', rotate: -180}],
								
								persons: [{role: 'Главный бухгалтер', name: 'Еремина Марина Анатольевна'}, {role: 'Бухгалтер', name: 'Голубева Надежда Владимировна'}]
							},
							{
								id: 'zNyzEs',
								
								parent: 'q4CfFQ',
								
								bounds: [{x: 967, y: 100, width: 133, height: 100}]
							},
							{
								id: 'xJpNPu',
								
								title: 'Заместитель по УПР',
								
								bounds: [{x: 967, y: 0, width: 133, height: 100}],
								
								labels: [{x: 1033.5, y: 50, text: 'УПР', size: 50}],
								
								persons: [{role: 'Заместитель директора по учебно-производственной работе', name: 'Елистратов Виктор Александрович'}]
							},
							{
								id: 'G2unQA',
								
								title: 'Приёмная директора',
								
								bounds: [{x: 1100, y: 0, width: 200, height: 200}],
								
								labels: [{x: 1200, y: 100, text: 'ПД'}],
								
								persons: [{role: 'Секретарь', name: 'Смирнова Диана Евгеньевна'}]
							},
							{
								id: 'JehYRE',
								
								title: 'Директор',
								
								bounds: [{x: 1300, y: 0, width: 200, height: 200}],
								
								labels: [{x: 1400, y: 100, text: 'Д'}],
								
								persons: [{role: 'Директор', name: 'Зимина Наталья Николаевна'}]
							},
							{
								id: 'ph2hYx',
								
								bounds: [{x: 1500, y: 0, width: 200, height: 100}, {x: 1500, y: 100, width: 100, height: 100}]
							},
							{
								id: 'ezkM2Y',
								
								bounds: [{x: 1600, y: 100, width: 100, height: 100}],
							},
							{
								id: 'BpPC6E',
								
								title: '45 кабинет',
								
								bounds: [{x: 1700, y: 0, width: 500, height: 200}],
								
								labels: [{x: 1950, y: 100, text: '№45'}],
								
								persons: [{role: 'Преподаватель', name: 'Шмакова Елена Евгеньевна'}]
							},
							{
								id: 'mU9u3k',
								
								parent: 'BpPC6E',
								
								bounds: [{x: 2100, y: 200, width: 100, height: 200}]
							},
							{
								id: '9qowji',
								
								bounds: [{x: 1800, y: 200, width: 200, height: 100}]
							},
							{
								id: 'gEYQDV',
								
								title: 'Заместитель по УВР',
								
								bounds: [{x: 1800, y: 300, width: 100, height: 100}],
								
								labels: [{x: 1850, y: 350, text: 'УВР', size: 40, rotate: -90}],
								
								persons: [{role: 'Заместитель директора по учебно-воспитательной работе', name: 'Макарова Елена Васильевна'}]
							},
							{
								id: 'UbAszP',
								
								bounds: [{x: 1900, y: 300, width: 100, height: 100}]
							},
							{
								id: 'Tsz7aa',
								
								title: 'Социальный педагог',
								
								bounds: [{x: 2000, y: 200, width: 100, height: 200}],
								
								labels: [{x: 2050, y: 300, text: 'СП', rotate: -180}],
								
								persons: [{role: 'Социальный педагог', name: 'Белогривова Галина Викторовна'}, {role: 'Начальник отдела по работе с детьми-сиротами и детьми, оставшимися без попечения родителей', name: 'Шведчикова Светлана Владимировна'}]
							},
							{
								id: 'y6L6hw',
								
								bounds: [{x: 400, y: 300, width: 300, height: 100}]
							},
							{
								id: 'uQdKHt',
								
								bounds: [{x: 1500, y: 300, width: 300, height: 100}]
							}
						],
						
						doors: [
							{x: 400, y: 250, rotate: 180, rooms: ['Ykc8AK', 'SX9WpS']},
							{x: 700, y: 350, rotate: 180, rooms: ['Ykc8AK', 'y6L6hw']},
							{x: 1500, y: 350, rotate: 180, rooms: ['Ykc8AK', 'uQdKHt']},
							{x: 2000, y: 250, rotate: 180, rooms: ['9qowji', 'Tsz7aa']},
							{x: 1800, y: 250, rotate: 180, rooms: ['Ykc8AK', '9qowji']},
							{x: 967, y: 150, rotate: 180, rooms: ['q4CfFQ', 'zNyzEs']},
							{x: 1100, y: 50, rotate: 180, rooms: ['G2unQA', 'xJpNPu']},
							{x: 1300, y: 150, rotate: 180, rooms: ['G2unQA', 'JehYRE']},
							
							{x: 50, y: 200, rooms: ['SX9WpS', 'p7BD9S']},
							{x: 450, y: 200, rooms: ['Ykc8AK', 'nwSRqU']},
							{x: 550, y: 200, rooms: ['Ykc8AK', 'NWsjMT']},
							{x: 650, y: 200, rooms: ['Ykc8AK', 'UgTyHR']},
							{x: 766.5, y: 200, rooms: ['Ykc8AK', 'PKJ6jj']},
							{x: 900, y: 200, rooms: ['Ykc8AK', 'q4CfFQ']},
							{x: 1200, y: 200, rooms: ['Ykc8AK', 'G2unQA']},
							{x: 1400, y: 200, rooms: ['Ykc8AK', 'JehYRE']},
							{x: 1550, y: 200, rooms: ['Ykc8AK', 'ph2hYx']},
							{x: 1650, y: 200, rooms: ['Ykc8AK', 'ezkM2Y']},
							{x: 1750, y: 200, rooms: ['Ykc8AK', 'BpPC6E']},
							{x: 2150, y: 200, rooms: ['BpPC6E', 'mU9u3k']},
							{x: 1850, y: 300, rooms: ['9qowji', 'gEYQDV']},
							{x: 1950, y: 300, rooms: ['9qowji', 'UbAszP']}
						]
					},
					{
						category: 'Учебный корпус',
						
						title: 'Третий этаж',
						
						width: 2200,
						height: 400,
						
						rooms: [
							{
								id: 'Fch6gc',
								
								bounds: [{x: 400, y: 200, width: 1400, height: 100}, {x: 700, y: 300, width: 800, height: 100}]
							},
							{
								id: 'HBwjde',
								
								bounds: [{x: 0, y: 0, width: 100, height: 200}]
							},
							{
								id: 'L9dus6',
								
								title: '61 кабинет',
								
								bounds: [{x: 0, y: 200, width: 400, height: 200}],
								
								labels: [{x: 200, y: 300, text: '№61'}],
								
								persons: [{role: 'Преподаватель', name: 'Смирнова Наталья Анатольевна'}]
							},
							{
								id: '6a7A5e',
								
								title: '63 кабинет',
								
								bounds: [{x: 100, y: 0, width: 400, height: 200}],
								
								labels: [{x: 300, y: 100, text: '№63'}],
								
								persons: [{role: 'Преподаватель', name: 'Александрова Елена Сергеевна'}]
							},
							{
								id: 'BLAEES',
								
								bounds: [{x: 500, y: 0, width: 200, height: 200}]
							},
							{
								id: '7AY3Ye',
								
								title: 'Педагогический кабинет',
								
								bounds: [{x: 700, y: 0, width: 400, height: 200}],
								
								labels: [{x: 900, y: 65, text: 'ПЕД. КАБИНЕТ', size: 50}, {x: 900, y: 135, text: '(ЗАВУЧ)', size: 50}],
								
								persons: [{role: 'Начальник отдела по учебной работе', name: 'Тер-Абрамова Ирина Рафаеловна'}]
							},
							{
								id: 'w2j2ZB',
								
								title: '67 кабинет',
								
								bounds: [{x: 1100, y: 0, width: 400, height: 200}],
								
								labels: [{x: 1300, y: 100, text: '№67'}],
								
								persons: [{role: 'Преподаватель', name: 'Кирилкин Александр Александрович'}]
							},
							{
								id: 'zKWZx9',
								
								bounds: [{x: 1500, y: 0, width: 200, height: 200}]
							},
							{
								id: '7pgaKW',
								
								title: '69 кабинет',
								
								bounds: [{x: 1700, y: 0, width: 500, height: 200}],
								
								labels: [{x: 1950, y: 100, text: '№69'}],
								
								persons: [{role: 'Преподаватель', name: 'Прошкина Анна Владимировна'}]
							},
							{
								id: 'qx4aVr',
								
								parent: '7pgaKW',
								
								bounds: [{x: 2100, y: 200, width: 100, height: 200}]
							},
							{
								id: 'TkdYVE',
								
								title: '71 кабинет',
								
								bounds: [{x: 1800, y: 200, width: 300, height: 200}],
								
								labels: [{x: 1950, y: 300, text: '№71'}],
								
								persons: [{role: 'Преподаватель', name: 'Гусева Екатерина Андреевна'}]
							},
							{
								id: 'wfsVZG',
								
								bounds: [{x: 400, y: 300, width: 300, height: 100}]
							},
							{
								id: 'y9uNyF',
								
								bounds: [{x: 1500, y: 300, width: 300, height: 100}]
							}
						],
						
						doors: [
							{x: 400, y: 250, rotate: 180, rooms: ['Fch6gc', 'L9dus6']},
							{x: 700, y: 350, rotate: 180, rooms: ['Fch6gc', 'wfsVZG']},
							{x: 1500, y: 350, rotate: 180, rooms: ['Fch6gc', 'y9uNyF']},
							{x: 1800, y: 250, rotate: 180, rooms: ['Fch6gc', 'TkdYVE']},
							
							{x: 50, y: 200, rooms: ['L9dus6', 'HBwjde']},
							{x: 450, y: 200, rooms: ['Fch6gc', '6a7A5e']},
							{x: 600, y: 200, rooms: ['Fch6gc', 'BLAEES']},
							{x: 1050, y: 200, rooms: ['Fch6gc', '7AY3Ye']},
							{x: 1450, y: 200, rooms: ['Fch6gc', 'w2j2ZB']},
							{x: 1600, y: 200, rooms: ['Fch6gc', 'zKWZx9']},
							{x: 1750, y: 200, rooms: ['Fch6gc', '7pgaKW']},
							{x: 2150, y: 200, rooms: ['7pgaKW', 'qx4aVr']},
						]
					},
					{
						category: 'Учебный корпус',
						
						title: 'Четвёртый этаж',
						
						width: 2200,
						height: 400,
						
						rooms: [
							{
								id: 'RBGAhR',
								
								bounds: [{x: 400, y: 200, width: 1400, height: 100}, {x: 700, y: 300, width: 800, height: 100}]
							},
							{
								id: 'TYkGM4',
								
								parent: 'b7HLUy',
								
								bounds: [{x: 0, y: 0, width: 100, height: 200}]
							},
							{
								id: 'b7HLUy',
								
								title: '81 кабинет',
								
								bounds: [{x: 0, y: 200, width: 400, height: 200}],
								
								labels: [{x: 200, y: 300, text: '№81'}],
								
								persons: [{role: 'Преподаватель', name: 'Ядыкина Людмила Алексеевна'}]
							},
							{
								id: 'QhX2JT',
								
								title: '80 кабинет',
								
								bounds: [{x: 100, y: 0, width: 400, height: 200}],
								
								labels: [{x: 300, y: 100, text: '№80'}],
								
								persons: [{role: 'Преподаватель', name: 'Волкова Наталья Анатольевна'}]
							},
							{
								id: 'XECMjA',
								
								bounds: [{x: 500, y: 0, width: 200, height: 200}]
							},
							{
								id: 'hJHw4x',
								
								title: '78 кабинет',
								
								bounds: [{x: 700, y: 0, width: 400, height: 200}],
								
								labels: [{x: 900, y: 100, text: '№78'}],
								
								persons: [{role: 'Преподаватель', name: 'Синявина Мария Николаевна'}]
							},
							{
								id: 'Qvp3mh',
								
								title: '77 кабинет',
								
								bounds: [{x: 1100, y: 0, width: 400, height: 200}],
								
								labels: [{x: 1300, y: 100, text: '№77'}],
								
								persons: [{role: 'Преподаватель', name: 'Киселёва Наталья Николаевна'}]
							},
							{
								id: 'XzxMRZ',
								
								bounds: [{x: 1500, y: 0, width: 200, height: 200}]
							},
							{
								id: 'UCSsbc',
								
								title: '75 кабинет',
								
								bounds: [{x: 1700, y: 0, width: 400, height: 200}],
								
								labels: [{x: 1900, y: 100, text: '№75'}],
								
								persons: [{role: 'Преподаватель', name: 'Васина Татьяна Васильевна'}]
							},
							{
								id: 'kJSuYw',
								
								parent: 'UCSsbc',
								
								bounds: [{x: 2100, y: 0, width: 100, height: 200}]
							},
							{
								id: 'ZdbD9Y',
								
								title: '74 кабинет',
								
								bounds: [{x: 1800, y: 200, width: 400, height: 200}],
								
								labels: [{x: 2000, y: 300, text: '№74'}],
								
								persons: [{role: 'Преподаватель', name: 'Максимова Людмила Борисовна'}]
							},
							{
								id: 's4duE7',
								
								bounds: [{x: 400, y: 300, width: 300, height: 100}]
							},
							{
								id: 'nB6Kuu',
								
								bounds: [{x: 1500, y: 300, width: 300, height: 100}]
							},
						],
						
						doors: [
							{x: 400, y: 250, rotate: 180, rooms: ['RBGAhR', 'b7HLUy']},
							{x: 700, y: 350, rotate: 180, rooms: ['RBGAhR', 's4duE7']},
							{x: 1500, y: 350, rotate: 180, rooms: ['RBGAhR', 'nB6Kuu']},
							{x: 1800, y: 250, rotate: 180, rooms: ['RBGAhR', 'ZdbD9Y']},
							{x: 2100, y: 150, rotate: 180, rooms: ['UCSsbc', 'kJSuYw']},
							
							{x: 50, y: 200, rooms: ['b7HLUy', 'TYkGM4']},
							{x: 450, y: 200, rooms: ['RBGAhR', 'QhX2JT']},
							{x: 600, y: 200, rooms: ['RBGAhR', 'XECMjA']},
							{x: 1050, y: 200, rooms: ['RBGAhR', 'hJHw4x']},
							{x: 1450, y: 200, rooms: ['RBGAhR', 'Qvp3mh']},
							{x: 1600, y: 200, rooms: ['RBGAhR', 'XzxMRZ']},
							{x: 1750, y: 200, rooms: ['RBGAhR', 'UCSsbc']},
						]
					},
					{
						category: 'Мастерские',
						
						title: 'Первый этаж',
						
						width: 1500,
						height: 600,
						
						rooms: [
							{
								id: '7eSzCk',
								
								bounds: [{x: 0, y: 0, width: 100, height: 225}, {x: 0, y: 225, width: 1500, height: 150}, {x: 0, y: 375, width: 100, height: 225}, {x: 1400, y: 375, width: 100, height: 225}],
							},
							{
								id: 'Q2JDNF',
								
								title: '8 кабинет',
								
								bounds: [{x: 100, y: 0, width: 200, height: 225}],
								
								labels: [{x: 200, y: 112.5, text: '№8'}]
							},
							{
								id: 'KwebhD',
								
								title: '9 кабинет',
								
								bounds: [{x: 300, y: 0, width: 150, height: 225}],
								
								labels: [{x: 375, y: 112.5, text: '№9', rotate: -180}]
							},
							{
								id: '8xRQZR',
								
								bounds: [{x: 100, y: 375, width: 300, height: 225}]
							},
							{
								id: 'xH58eG',
								
								title: '10 кабинет',
								
								bounds: [{x: 450, y: 0, width: 275, height: 225}],
								
								labels: [{x: 587.5, y: 112.5, text: '№10'}],
								
								persons: [{role: 'Мастер производственного обучения', name: 'Кирилкин Александр Александрович'}]
							},
							{
								id: '3TeQx6',
								
								bounds: [{x: 400, y: 375, width: 100, height: 225}]
							},
							{
								id: 'za5859',
								
								title: '3 кабинет',
								
								bounds: [{x: 500, y: 375, width: 200, height: 225}],
								
								labels: [{x: 600, y: 487.5, text: '№3'}],
								
								persons: [{role: 'Мастер производственного обучения', name: 'Зимин Алексей Викторович'}]
							},
							{
								id: 'pbDmzq',
								
								title: '11 кабинет',
								
								bounds: [{x: 725, y: 0, width: 275, height: 225}],
								
								labels: [{x: 862.5, y: 112.5, text: '№11'}],
								
								persons: [{role: 'Мастер производственного обучения', name: 'Волкова Наталья Анатольевна'}]
							},
							{
								id: 'tk4W7A',
								
								title: '2 кабинет',
								
								bounds: [{x: 700, y: 375, width: 450, height: 225}],
								
								labels: [{x: 925, y: 487.5, text: '№2'}]
							},
							{
								id: 'cJ5Lvt',
								
								title: '12 кабинет',
								
								bounds: [{x: 1000, y: 0, width: 150, height: 225}],
								
								labels: [{x: 1075, y: 112.5, text: '№12', rotate: -180}],
								
								persons: [{role: 'Мастер производственного обучения', name: 'Кохан Виталий Владимирович'}]
							},
							{
								id: 'zvdys3',
								
								parent: 'cJ5Lvt',
								
								bounds: [{x: 1150, y: 0, width: 150, height: 150}]
							},
							{
								id: 'gqc6be',
								
								bounds: [{x: 1150, y: 150, width: 150, height: 75}]
							},
							{
								id: 'Ye38yV',
								
								bounds: [{x: 1150, y: 375, width: 250, height: 225}]
							},
							{
								id: 'Qydke4',
								
								bounds: [{x: 1300, y: 0, width: 200, height: 225}]
							}
						],
						
						doors: [
							{x: 1150, y: 50, rotate: 180, rooms: ['cJ5Lvt', 'zvdys3']},
							{x: 1150, y: 487.5, rotate: 180, rooms: ['tk4W7A', 'Ye38yV']},
							
							{x: 200, y: 225, rooms: ['7eSzCk', 'Q2JDNF']},
							{x: 375, y: 225, rooms: ['7eSzCk', 'KwebhD']},
							{x: 500, y: 225, rooms: ['7eSzCk', 'xH58eG']},
							{x: 950, y: 225, rooms: ['7eSzCk', 'pbDmzq']},
							{x: 1075, y: 225, rooms: ['7eSzCk', 'cJ5Lvt']},
							{x: 1225, y: 225, rooms: ['7eSzCk', 'gqc6be']},
							{x: 1450, y: 225, rooms: ['7eSzCk', 'Qydke4']},
							{x: 1225, y: 375, rooms: ['7eSzCk', 'Ye38yV']},
							{x: 800, y: 375, rooms: ['7eSzCk', 'tk4W7A']},
							{x: 1050, y: 375, rooms: ['7eSzCk', 'tk4W7A']},
							{x: 600, y: 375, rooms: ['7eSzCk', 'za5859']},
							{x: 450, y: 375, rooms: ['7eSzCk', '3TeQx6']},
							{x: 250, y: 375, rooms: ['7eSzCk', '8xRQZR']},
						]
					},
					{
						category: 'Мастерские',
						
						title: 'Второй этаж',
						
						width: 1500,
						height: 600,
						
						rooms: [
							{
								id: 'd6anfP',
								
								bounds: [{x: 0, y: 0, width: 100, height: 225}, {x: 0, y: 225, width: 1500, height: 150}, {x: 1400, y: 375, width: 100, height: 225}],
							},
							{
								id: 'x8dxmx',
								
								bounds: [{x: 0, y: 375, width: 100, height: 225}],
							},
							{
								id: 'ChnjQK',
								
								title: '21 кабинет',
								
								bounds: [{x: 100, y: 0, width: 450, height: 225}],
								
								labels: [{x: 325, y: 112.5, text: '№21'}]
							},
							{
								id: 'nQB5A2',
								
								title: '19 кабинет',
								
								bounds: [{x: 100, y: 375, width: 450, height: 225}],
								
								labels: [{x: 325, y: 487.5, text: '№19'}],
								
							},
							{
								id: 'GjetpQ',
								
								title: '22 кабинет',
								
								bounds: [{x: 550, y: 0, width: 300, height: 225}],
								
								labels: [{x: 700, y: 112.5, text: '№22'}],
								
								persons: [{role: 'Мастер производственного обучения', name: 'Налётов Антон Сергеевич'}]
							},
							{
								id: 'gmfxbE',
								
								title: '18 кабинет',
								
								bounds: [{x: 550, y: 375, width: 200, height: 225}],
								
								labels: [{x: 650, y: 487.5, text: '№18'}]
							},
							{
								id: 'FNaB82',
								
								title: '23 кабинет',
								
								bounds: [{x: 850, y: 0, width: 300, height: 225}],
								
								labels: [{x: 1000, y: 112.5, text: '№23'}],
								
								persons: [{role: 'Преподаватель', name: 'Микоева Галина Риягиевна'}]
							},
							{
								id: 'R5JeZm',
								
								title: '17 кабинет',
								
								bounds: [{x: 750, y: 375, width: 400, height: 225}],
								
								labels: [{x: 950, y: 487.5, text: '№17'}],
								
								persons: [{role: 'Мастер производственного обучения', name: 'Кирилкин Александр Александрович'}]
							},
							{
								id: 'YsS8D6',
								
								title: 'Старший мастер',
								
								bounds: [{x: 1150, y: 0, width: 150, height: 112.5}, {x: 1150, y: 112.5, width: 75, height: 112.5}],
								
								labels: [{x: 1187.5, y: 112.5, text: 'СТ.МАСТЕР', size: 36, rotate: -180}],
								
								persons: [{role: 'Старший мастер', name: 'Ханталин Юрий Петрович'}]
							},
							{
								id: 'VMtnfT',
								
								bounds: [{x: 1225, y: 112.5, width: 75, height: 112.5}]
							},
							{
								id: 'R4zNmQ',
								
								bounds: [{x: 1150, y: 375, width: 250, height: 225}]
							},
							{
								id: 'JDH9Z3',
								
								title: 'Заместитель по АХЧ',
								
								bounds: [{x: 1300, y: 0, width: 200, height: 225}],
								
								labels: [{x: 1400, y: 112.5, text: 'АХЧ', size: 50}],
								
								persons: [
									{role: 'Заместитель директора по административно-хозяйственной части', name: 'Беседин Андрей Александрович'},
									{role: 'Заведующий хозяйственной частью', name: 'Иванова Ирина Михайловна'}
								]
							}
						],
						
						doors: [
							{x: 1225, y: 168.75, rotate: 180, rooms: ['YsS8D6', 'VMtnfT']},
							
							{x: 50, y: 375, rooms: ['d6anfP', 'x8dxmx']},
							
							{x: 200, y: 225, rooms: ['d6anfP', 'ChnjQK']},
							{x: 450, y: 225, rooms: ['d6anfP', 'ChnjQK']},
							
							{x: 200, y: 375, rooms: ['d6anfP', 'nQB5A2']},
							{x: 450, y: 375, rooms: ['d6anfP', 'nQB5A2']},
							
							{x: 650, y: 375, rooms: ['d6anfP', 'gmfxbE']},
							
							{x: 750, y: 225, rooms: ['d6anfP', 'GjetpQ']},
							
							{x: 1050, y: 225, rooms: ['d6anfP', 'FNaB82']},
							{x: 1187.5, y: 225, rooms: ['d6anfP', 'YsS8D6']},
							
							{x: 1050, y: 375, rooms: ['d6anfP', 'R5JeZm']},
							
							{x: 1275, y: 375, rooms: ['d6anfP', 'R4zNmQ']},
							
							{x: 1450, y: 225, rooms: ['d6anfP', 'JDH9Z3']},
						]
					}
				]
			};
		}
	}
</script>

<style>
	.maps-list {
		display: flex;
		
		flex-wrap: wrap;
		
		gap: 30px 15px;
	}
	
	.maps-list > .list-item {
		display: flex;
		
		flex-direction: column;
		
		row-gap: 15px;
	}
	
	.maps-list > .list-item > .text {
		font-size: .85rem;
		font-weight: bold;
		
		word-break: break-word;
		
		text-transform: uppercase;
		
		text-align: center;
	}
	
	@media (max-width: 767.9px) {
		.maps-list > .list-item {
			width: 100%;
		}
	}
	
	@media (min-width: 768px) {
		.maps-list > .list-item {
			width: calc((100% - 15px) / 2);
		}
	}
	
	.room-information {
		display: flex;
		
		flex-direction: column;
		
		row-gap: 20px;
	}
	
	.room-information > .room-block {
		display: flex;
		
		flex-direction: column;
		
		row-gap: 5px;
	}
	
	.room-information > .room-block > .room-block-title {
		font-size: .85rem;
		font-weight: bold;
		
		word-break: break-word;
		
		text-transform: uppercase;
	}
	
	.room-information .room-details {
		display: flex;
		
		flex-direction: column;
		
		row-gap: 5px;
	}
	
	.room-information .room-details > .room-detail {
		word-break: break-word;
	}
	
	.room-information .room-details > .room-detail::before {
		content: '•';
		
		margin-right: 10px;
		
		color: var(--accent-color);
		
		transition: color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.room-information .room-details > .room-detail > .detail-name {
		font-weight: bold;
		
		color: var(--accent-color);
		
		transition: color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.room-information > .room-maps {
		display: flex;
		
		flex-direction: column;
		
		row-gap: 15px;
	}
	
	.room-information > .room-maps > .room-map {
		display: flex;
		
		flex-direction: column;
		
		row-gap: 10px;
	}
	
	.room-information > .room-maps > .room-map > .text {
		font-size: .85rem;
		font-weight: bold;
		
		word-break: break-word;
		
		text-transform: uppercase;
		
		text-align: center;
	}
</style>
export default new class {
	
	open({ name, version = 1, objectStores = [] }) {
		const request = indexedDB.open(name, version);
		
		request.onupgradeneeded = () => {
			const database = request.result;
			
			Array.from(database.objectStoreNames).map((name) => database.deleteObjectStore(name));
			
			objectStores.forEach(({ name, keyPath, autoIncrement = false, indexes = [] }) => {
				const table = database.createObjectStore(name, {keyPath, autoIncrement});
				
				indexes.forEach(({ name, keyPath, unique = false, multiEntry = false }) => {
					table.createIndex(name, keyPath, {unique, multiEntry});
				});
			});
		};
		
		return request.promise;
	}
	
}

Object.defineProperty(IDBRequest.prototype, 'promise', {
	get() {
		return new Promise((resolve, reject) => {
			if(this.readyState === 'done') {
				if(this.result !== undefined) return resolve(this.result);
				if(this.error !== null) return reject(this.error);
			}
			
			this.onsuccess = () => resolve(this.result);
			this.onerror = () => reject(this.error);
		});
	}
});
<template>
	<section class="content-section content-wrapper carousel-wrapper">
		<vue-carousel v-if="files.length > 0" :images="files" :loading="$store.state.initialization" @preview="({ hash }) => $router.push({query: {...$route.query, image: hash}})" @refresh="!$store.state.initialization && $store.dispatch('init')" />
		
		<div v-if="files.length === 0" class="large-banner">
			<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
				<path d="M.5 6a.5.5 0 0 0-.488.608l1.652 7.434A2.5 2.5 0 0 0 4.104 16h5.792a2.5 2.5 0 0 0 2.44-1.958l.131-.59a3 3 0 0 0 1.3-5.854l.221-.99A.5.5 0 0 0 13.5 6zM13 12.5a2.01 2.01 0 0 1-.316-.025l.867-3.898A2.001 2.001 0 0 1 13 12.5" />
				<path d="m4.4.8-.003.004-.014.019a4.167 4.167 0 0 0-.204.31 2.327 2.327 0 0 0-.141.267c-.026.06-.034.092-.037.103v.004a.593.593 0 0 0 .091.248c.075.133.178.272.308.445l.01.012c.118.158.26.347.37.543.112.2.22.455.22.745 0 .188-.065.368-.119.494a3.31 3.31 0 0 1-.202.388 5.444 5.444 0 0 1-.253.382l-.018.025-.005.008-.002.002A.5.5 0 0 1 3.6 4.2l.003-.004.014-.019a4.149 4.149 0 0 0 .204-.31 2.06 2.06 0 0 0 .141-.267c.026-.06.034-.092.037-.103a.593.593 0 0 0-.09-.252A4.334 4.334 0 0 0 3.6 2.8l-.01-.012a5.099 5.099 0 0 1-.37-.543A1.53 1.53 0 0 1 3 1.5c0-.188.065-.368.119-.494.059-.138.134-.274.202-.388a5.446 5.446 0 0 1 .253-.382l.025-.035A.5.5 0 0 1 4.4.8m3 0-.003.004-.014.019a4.167 4.167 0 0 0-.204.31 2.327 2.327 0 0 0-.141.267c-.026.06-.034.092-.037.103v.004a.593.593 0 0 0 .091.248c.075.133.178.272.308.445l.01.012c.118.158.26.347.37.543.112.2.22.455.22.745 0 .188-.065.368-.119.494a3.31 3.31 0 0 1-.202.388 5.444 5.444 0 0 1-.253.382l-.018.025-.005.008-.002.002A.5.5 0 0 1 6.6 4.2l.003-.004.014-.019a4.149 4.149 0 0 0 .204-.31 2.06 2.06 0 0 0 .141-.267c.026-.06.034-.092.037-.103a.593.593 0 0 0-.09-.252A4.334 4.334 0 0 0 6.6 2.8l-.01-.012a5.099 5.099 0 0 1-.37-.543A1.53 1.53 0 0 1 6 1.5c0-.188.065-.368.119-.494.059-.138.134-.274.202-.388a5.446 5.446 0 0 1 .253-.382l.025-.035A.5.5 0 0 1 7.4.8m3 0-.003.004-.014.019a4.077 4.077 0 0 0-.204.31 2.337 2.337 0 0 0-.141.267c-.026.06-.034.092-.037.103v.004a.593.593 0 0 0 .091.248c.075.133.178.272.308.445l.01.012c.118.158.26.347.37.543.112.2.22.455.22.745 0 .188-.065.368-.119.494a3.198 3.198 0 0 1-.202.388 5.385 5.385 0 0 1-.252.382l-.019.025-.005.008-.002.002A.5.5 0 0 1 9.6 4.2l.003-.004.014-.019a4.149 4.149 0 0 0 .204-.31 2.06 2.06 0 0 0 .141-.267c.026-.06.034-.092.037-.103a.593.593 0 0 0-.09-.252A4.334 4.334 0 0 0 9.6 2.8l-.01-.012a5.099 5.099 0 0 1-.37-.543A1.53 1.53 0 0 1 9 1.5c0-.188.065-.368.119-.494.059-.138.134-.274.202-.388a5.446 5.446 0 0 1 .253-.382l.025-.035A.5.5 0 0 1 10.4.8" />
			</svg>
			
			<p v-if="!$store.state.initialization" class="text">В данный момент нет актуального меню столовой! <span class="action" @click="$store.dispatch('init')">Обновить</span></p>
			<p v-else class="text">Пожалуйста, подождите! Идёт загрузка актуального меню столовой!</p>
		</div>
	</section>
</template>

<script>
	import VueCarousel from '@/components/VueCarousel';
	
	export default {
		name: 'MenuView',
		
		components: {VueCarousel},
		
		computed: {
			files() {
				return this.$store.state.files.filter(({ type }) => type === 'menu').map(({ base64, url, hash }) => ({src: base64 || url, hash}));
			}
		}
	}
</script>

<style>
	.carousel-wrapper {
		height: 1024px;
		
		min-height: 250px;
		max-height: 100%;;
	}
	
	.large-banner {
		display: flex;
		
		flex-direction: column;
		
		align-items: center;
		justify-content: center;
		
		height: 500px;
		
		row-gap: 75px;
		
		padding: 0 20px;
		
		background-color: var(--secondary-background-color);
		
		border: 1px solid var(--secondary-border-color);
		
		border-radius: 6px;
		
		transition: background-color, border-color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.large-banner > .icon {
		width: 125px;
		height: 125px;
	}
	
	.large-banner > .text {
		text-align: center;
		
		font-weight: bold;
		font-size: .9rem;
		
		text-transform: uppercase;
		
		max-width: 350px;
	}
	
	.large-banner > .text > .action {
		cursor: pointer;
		
		color: var(--accent-color);
		
		user-select: none;
		
		transition: opacity, color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.large-banner > .text > .action:hover {opacity: .8}
	.large-banner > .text > .action:active {opacity: .6}
</style>
import { createApp } from 'vue';

import Main from '@/Main.vue';

import $store from '@/store';
import $router from '@/router';

$store.dispatch('registerServiceWorker');

const app = createApp(Main).use($store).use($router);

import $utils from '@/utils';

app.use($utils);

app.mount('body');
<template>
	<canvas :width="__width" :height="__height" ref="canvas" />
</template>

<script>
	import clipperLib from 'clipper-lib';
	
	export default {
		name: 'VueMap',
		
		emits: ['select'],
		
		props: {
			width: {type: [Number, String], required: true},
			height: {type: [Number, String], required: true},
			
			rooms: {type: Array, required: true},
			
			doors: {type: Array, required: false, default: []},
			
			room: {type: String, required: false, default: undefined},
			
			readonly: {type: Boolean, required: false, default: false}
		},
		
		data() {
			return {
				_wall: {
					size: 6
				},
				
				_room: undefined
			};
		},
		
		computed: {
			__width() {
				return Number(this.width) + this._wall.size;
			},
			
			__height() {
				return Number(this.height) + this._wall.size;
			},
			
			__rooms() {
				const rooms = this.rooms.map((item) => ({...item, paths: this.getPath(item.bounds), active: this.isActive(item)}));
				
				return rooms.map((item) => ({...item, parent: rooms.find(({ id }) => id === item.parent)})).sort((a, b) => a.active ? 1 : -1);
			},
			
			__doors() {
				return this.doors;
			},
			
			__room() {
				return (this.readonly ? undefined : this._room) ?? this.room;
			},
			
			__colors() {
				const styles = window.getComputedStyle(this.$refs.canvas);
				
				return {
					scheme: this.$store.state.scheme,
					
					room: {
						default: styles.getPropertyValue('--secondary-background-color'),
						
						active: styles.getPropertyValue('--accent-color'),
						inactive: styles.getPropertyValue('--secondary-background-color'),
					},
					
					wall: {
						default: styles.getPropertyValue('--accent-color'),
						
						active: styles.getPropertyValue('--danger-color'),
						inactive: styles.getPropertyValue('--secondary-text-color'),
					},
					
					door: {
						default: styles.getPropertyValue('--accent-contrast-color'),
						
						active: styles.getPropertyValue('--success-color'),
						inactive: styles.getPropertyValue('--accent-contrast-color'),
					},
					
					label: {
						default: styles.getPropertyValue('--primary-text-color'),
						
						active: styles.getPropertyValue('--accent-contrast-color'),
						inactive: styles.getPropertyValue('--secondary-text-color'),
					}
				};
			}
		},
		
		methods: {
			onMouseMove(event) {
				const x = event.offsetX / (this.$refs.canvas.offsetWidth / this.width);
				const y = event.offsetY / (this.$refs.canvas.offsetHeight / this.height);
				
				const room = this.findRooms(x, y).find(({ parent, title, }) => parent?.title !== undefined || title !== undefined);
				
				this._room = room === undefined ? undefined : room.parent?.id ?? room.id;
			},
			
			onMouseLeave(event) {
				this._room = undefined;
			},
			
			onClick(event) {
				if(this.readonly) return;
				
				const x = event.offsetX / (this.$refs.canvas.offsetWidth / this.width);
				const y = event.offsetY / (this.$refs.canvas.offsetHeight / this.height);
				
				const room = this.findRooms(x, y).find(({ parent, title, }) => parent?.title !== undefined || title !== undefined);
				
				if(room !== undefined) this.$emit('select', room.parent?.id ?? room.id);
			},
			
			findRooms(x, y) {
				return this.__rooms.filter(({ bounds }) => bounds.find((item) => (x >= item.x && x <= item.x + item.width) && (y >= item.y && y <= item.y + item.height)));
			},
			
			draw() {
				const canvas = this.$refs.canvas, ctx = canvas.getContext('2d');
				
				ctx.save();
				
				ctx.lineWidth = this._wall.size;
				
				ctx.translate(this._wall.size / 2, this._wall.size / 2);
				
				this.__rooms.forEach(({ labels = [], paths, active }) => {
					ctx.strokeStyle = active ? this.__colors.wall.active : this.__room !== undefined ? this.__colors.wall.inactive : this.__colors.wall.default;
					ctx.fillStyle = active ? this.__colors.room.active : this.__room !== undefined ? this.__colors.room.inactive : this.__colors.room.default;
					
					paths.forEach((path) => {
						ctx.beginPath();
						
						path.forEach(({ x, y }, i) => i === 0 ? ctx.moveTo(x, y) : ctx.lineTo(x, y));
						
						ctx.closePath();
						ctx.fill();
					});
					
					labels.forEach(({ text, x, y, size = 70, rotate = 0 }) => {
						ctx.save();
						
						ctx.fillStyle = active ? this.__colors.label.active : this.__room !== undefined ? this.__colors.label.inactive : this.__colors.label.default;
						
						ctx.textAlign = 'center';
						ctx.textBaseline = 'middle';
						
						ctx.font = size + 'px Arial, sans-serif';
						
						ctx.translate(x, y);
						
						ctx.rotate(Math.PI / 360 * rotate);
						
						ctx.fillText(text, 0, 6);
						
						ctx.restore();
					});
					
					paths.forEach((path) => {
						ctx.beginPath();
						
						path.forEach(({ x, y }, i) => i === 0 ? ctx.moveTo(x, y) : ctx.lineTo(x, y));
						
						ctx.closePath();
						ctx.stroke();
					});
					
				});
				
				this.__doors.forEach(({ x, y, size = 50, rotate = 0, rooms = [] }) => {
					ctx.save();
					
					ctx.fillStyle = rooms.includes(this.__room) ? this.__colors.door.active : this.__room !== undefined ? this.__colors.door.inactive : this.__colors.door.default;
					
					ctx.translate(x, y);
					
					ctx.rotate(Math.PI / 360 * rotate);
					
					ctx.fillRect(-size / 2, -this._wall.size / 2, size, this._wall.size);
					
					ctx.restore();
				});
				
				ctx.restore();
			},
			
			getPath(rectangles) {
				const clipper = new clipperLib.Clipper(), solution = new clipperLib.Paths();
				
				clipper.AddPaths(rectangles.map(({ x: X, y: Y, width: W, height: H }) => [{X, Y}, {X: X + W, Y}, {X: X + W, Y: Y + H}, {X, Y: Y + H}]), clipperLib.PolyType.ptSubject, true);
				clipper.Execute(clipperLib.ClipType.ctUnion, solution, clipperLib.PolyFillType.pftPositive, clipperLib.PolyFillType.pftNegative);
				
				return solution.map((item) => item.map(({ X: x, Y: y }) => ({x, y})));
			},
			
			isActive(room) {
				return this.__room !== undefined && (this.__room === room.id || this.__room === room.parent);
			}
		},
		
		watch: {
			'$store.state.scheme'() {
				this.draw();
			},
			
			__rooms() {
				this.draw();
			},
			
			__room() {
				this.draw();
			}
		},
		
		mounted() {
			this.draw();
			
			this.$refs.canvas.addEventListener('mousemove', this.onMouseMove, {passive: true});
			this.$refs.canvas.addEventListener('mouseleave', this.onMouseLeave, {passive: true});
			
			this.$refs.canvas.addEventListener('click', this.onClick, {passive: true});
		},
		
		beforeUnmount() {
			this.$refs.canvas.removeEventListener('mousemove', this.onMouseMove);
			this.$refs.canvas.removeEventListener('mouseleave', this.onMouseLeave);
			
			this.$refs.canvas.removeEventListener('click', this.onClick);
		}
	}
</script>
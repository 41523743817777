import { unref } from 'vue';

import { createStore } from 'vuex';

import { register as registerServiceWorker } from 'register-service-worker';

import $utils from '@/utils';

import $router from '@/router';

import $indexedDB from '@/utils/indexedDB';
import $caches from '@/utils/caches';

import axios from 'axios';

const $store = createStore({
	state: {
		origin: {
			web: 'https://pptbot.ru/web',
			api: 'https://pptbot.ru/api'
		},
		
		date: new Date(),
		
		initialization: false,
		
		scheme: 'dark',
		
		files: [],
		
		timetables: [],
		
		database: {
			name: 'pptbot',
			version: 2,
			
			objectStores: [
				{
					name: 'files',
					keyPath: 'hash',
					
					indexes: [
						{name: 'hash', keyPath: 'hash', unique: true},
						
						{name: 'url', keyPath: 'url'},
						{name: 'type', keyPath: 'type'},
						{name: 'created', keyPath: 'created'}
					]
				},
				{
					name: 'timetables',
					keyPath: 'hash',
					
					indexes: [
						{name: 'hash', keyPath: 'hash', unique: true},
						
						{name: 'title', keyPath: 'title'},
						{name: 'lessons', keyPath: 'lessons'},
						{name: 'days', keyPath: 'days'},
						{name: 'start', keyPath: 'start'},
						{name: 'end', keyPath: 'end'},
						{name: 'created', keyPath: 'created'}
					]
				}
			]
		},
		
		sw: {
			registration: undefined
		}
	},
	
	getters: {
		timetable({ date, timetables }) {
			timetables = timetables.sort((a, b) => (b.start ?? b.created) > (a.start ?? a.created) ? 1 : (b.start ?? b.created) === (a.start ?? a.created) ? 0 : -1);
			
			const getDay = (date) => ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][new Date(date).getDay()];
			
			const getLessons = (date) => {
				const vacation = getVacation(date);
				
				date = vacation > 0 ? Date.parse(new Date(date).toDateString()) + vacation : date;
				
				const timetable = timetables.find(({ lessons, days, start, end }) => lessons !== null && (start === null || start <= date) && (days === null || days.includes(getDay(date))) && (end === null || end >= date));
				
				if(timetable === undefined) return undefined;
				
				const lessons = timetable.lessons.map(({ title, start, end }) => ({title, start: Date.parse(new Date(date).toDateString() + ' ' + start), end: Date.parse(new Date(date).toDateString() + ' ' + end)}));
				
				return lessons.map(({ end }) => end).sort((a, b) => b - a)[0] <= date ? getLessons(Date.parse(new Date(date).toDateString()) + 86400000) : {...timetable, lessons};
			};
			
			const getVacation = (date) => {
				const vacation = timetables.find(({ lessons, days, start, end }) => lessons === null && (start === null || start <= date) && (days === null || days.includes(getDay(date))) && (end === null || end >= date));
				
				if(vacation === undefined) return 0;
				
				const period = vacation.days !== null ? vacation.days.includes(getDay(date)) ? 86400000 : 0 : vacation.end > date ? Math.ceil((vacation.end - date) / 86400000 + 1) * 86400000 : 0;
				
				return period > 0 ? period + getVacation(date + period) : 0;
			};
			
			const current = getLessons(date.getTime()), next = getLessons(date.getTime() + 86400000);
			
			if(current === undefined) return {date, lessons: [], current: null, next: null};
			
			const lessons = current.lessons.map((item, index) => Object.assign({index}, item.end <= date.getTime() ? next.lessons.find(({ title }) => title === item.title) : item)).filter(({ end }) => end >= date.getTime());
			
			return {date, lessons, current: lessons.find(({ start, end }) => start <= date.getTime() && end > date.getTime()) ?? null, next: lessons.sort(({ start: a }, { start: b }) => a - b).find(({ start }) => start > date.getTime()) ?? null};
		},
		
		capabilities() {
			const capabilities = [];
			
			const route = unref($router.currentRoute);
			
			if(route.query['keyboard'] !== '0') capabilities.push('keyboard');
			if(route.query['external-redirect'] !== '0') capabilities.push('external-redirect');
			
			return capabilities;
		}
	},
	
	actions: {
		setScheme({ state }, scheme = 'dark') {
			if(!['dark', 'light'].includes(scheme)) return;
			
			if(scheme == 'dark') localStorage.removeItem('color-scheme');
				else localStorage.setItem('color-scheme', scheme)
			
			state.scheme = scheme;
		},
		
		init: ({ state }) => new Promise(async (resolve, reject) => {
			if(state.initialization) return reject(new Error('Initialization has already started'));
			
			state.initialization = true;
			
			const database = await $indexedDB.open(state.database).catch((error) => console.error('Ошибка обращения к базе данных', error));
			
			if(!(database instanceof IDBDatabase)) {
				state.initialization = false;
				
				return reject(new Error('Error connecting to database'));
			}
			
			const getFiles = (database) => new Promise(async (resolve, reject) => {
				const files = await Promise.all(await database.transaction('files').objectStore('files').getAll().promise.then((items) => items.sort(({ created: a }, { created: b }) => b - a)
					.map(async (item) => ({...item, base64: await $caches.getBase64(item.url).catch(() => undefined)}))).catch(reject)).catch(reject);
				
				if(files instanceof Array) resolve(files); else reject(new Error('Could not find the files'));
			});
			
			state.files = await getFiles(database).catch((error) => console.error('Ошибка получения списка файлов', error)) ?? [];
			
			state.timetables = await database.transaction('timetables', 'readwrite').objectStore('timetables').getAll().promise
				.catch((error) => console.error('Ошибка получения списка расписаний', error)) ?? [];
			
			const response = await axios(state.origin.api + '/init', {method: 'POST', responseType: 'json', timeout: 4000}).then(({ data }) => data)
				.catch((error) => console.error('Ошибка обращения к серверу', error));
			
			if($utils.isObject(response) && $utils.isObject(response.result)) {
				const { result } = response;
				
				if($utils.isArray(result.files)) {
					const files = result.files.map(({ hash, type, created }) => ({url: state.origin.api + '/files/' + hash, hash, type, created}));
					
					await Promise.allSettled(state.files.filter((file) => !files.find(({ hash }) => hash === file.hash)).map(({ hash }) => database.transaction('files', 'readwrite').objectStore('files').delete(hash).promise));
					await Promise.allSettled(files.filter((file) => !state.files.find(({ hash }) => hash === file.hash)).map((file) => database.transaction('files', 'readwrite').objectStore('files').add(file).promise));
					
					if($caches.isSupported) {
						const { cache, files: cached } = await $caches.get('files').catch((error) => console.error('Ошибка обращения к базе кеша', error)) ?? {};
						
						if(cache instanceof Cache) {
							if(cached.filter((url) => !files.find((file) => file.url === url)).length > 0) await Promise.allSettled(cached.filter((url) => !files.find((file) => file.url === url)).map((url) => cache.delete(url)));
							if(files.filter(({ url }) => cached.indexOf(url) === -1).length > 0) await Promise.allSettled(files.filter(({ url }) => cached.indexOf(url) === -1).map(({ url }) => cache.add(url)));
						}
					}
					
					state.files = await getFiles(database).catch((error) => console.error('Ошибка получения списка файлов', error)) ?? [];
				}
				
				if($utils.isArray(result.timetables)) {
					const timetables = result.timetables.map(({ hash, title, lessons, days, start, end, created }) => ({hash, title, lessons: lessons?.map(({ title, start, end }) => ({title, start, end})) ?? null, days, start, end, created}));
					
					await Promise.allSettled(state.timetables.filter((timetable) => !timetables.find(({ hash }) => hash === timetable.hash)).map(({ hash }) => database.transaction('timetables', 'readwrite').objectStore('timetables').delete(hash).promise));
					await Promise.allSettled(timetables.filter((timetable) => !state.timetables.find(({ hash }) => hash === timetable.hash)).map((timetable) => database.transaction('timetables', 'readwrite').objectStore('timetables').add(timetable).promise));
					
					state.timetables = await database.transaction('timetables', 'readwrite').objectStore('timetables').getAll().promise;
				}
			}
			
			state.initialization = false;
			
			resolve(true);
		}),
		
		registerServiceWorker({ state }) {
			if(process.env.NODE_ENV === 'production') registerServiceWorker(process.env.BASE_URL + 'service-worker.js', {
				registered: (registration) => state.sw = {...state.sw, registration},
				
				updated: () => document.location.reload(),
				
				ready: (registration) => state.sw = {...state.sw, registration, ready: true},
				
				error: (error) => state.sw = {...state.sw, error}
			});
		}
	}
});

/* Date Update */

setInterval(() => $store.state.date = new Date(), 1000);

/* ReInitialization */

setInterval(() => $store.dispatch('init').catch(() => null), 120000);

/* Service-Worker Update */

setInterval(() => $store.state.sw.registration?.update(), 60000);

/* Appearance */

const scheme = localStorage.getItem('color-scheme');

$store.state.scheme = ['light', 'dark'].includes(scheme) ? scheme : 'dark';

export default $store;
import { createRouter, createWebHistory } from 'vue-router';

import $store from '@/store';

import LessonsView from '@/views/LessonsView';
import MenuView from '@/views/MenuView';
import TimetableView from '@/views/TimetableView';
import BuildingView from '@/views/BuildingView';

const $router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	
	linkExactActiveClass: '',
	linkActiveClass: '',
	
	routes: [
		{path: '/lessons', name: 'Lessons', component: LessonsView},
		{path: '/menu', name: 'Menu', component: MenuView},
		{path: '/timetable', name: 'Timetable', component: TimetableView},
		{path: '/building', name: 'Building', component: BuildingView},
		
		{path: '/:catchAll(.*)*', redirect: {name: 'Lessons', params: null}}
	]
});

$router.beforeEach((to, { matched }) => {
	if(matched.length === 0) $store.dispatch('init').catch(() => null);
});

export default $router;
<template>
	<div class="slider" :class="__class" :disabled="disabled ? '' : undefined" ref="slider">
		<input class="slider-input" type="hidden" :name="name" :value="value" disabled readonly>
		
		<div class="slider-track"></div>
		
		<div class="slider-thumb" :style="{left: __points[__value]?.x + 'px'}" ref="thumb"></div>
	</div>
</template>

<script>
	export default {
		name: 'VueSlider',
		
		emits: ['value'],
		
		props: {
			value: {type: [String, Number], required: false},
			
			name: {type: String, required: false},
			
			min: {type: [String, Number], required: false, default: 0},
			max: {type: [String, Number], required: false, default: 100},
			step: {type: [String, Number], required: false, default: 1},
			
			readonly: {type: [Boolean, String, Number], required: false},
			disabled: {type: [Boolean, String, Number], required: false}
		},
		
		data() {
			return {
				_value: this.value,
				
				_size: {
					slider: {
						width: 0,
						height: 0
					},
					
					thumb: {
						width: 0,
						height: 0
					}
				},
				
				_pointer: {
					mouse: undefined,
					touch: undefined,
				},
				
				_observer: {
					resize: {
						slider: undefined,
						thumb: undefined
					}
				}
			};
		},
		
		computed: {
			__class() {
				return {
					'slider-readonly': this.__readonly,
					
					'slider-moving': this._pointer.mouse?.active || this._pointer.touch?.active
				};
			},
			
			__value() {
				return this.getFixedValue(this._value);
			},
			
			__min() {
				return Number(this.min);
			},
			
			__max() {
				return this.__min + this.__step * Math.floor((this.max - this.__min) / this.__step);
			},
			
			__step() {
				return Number(this.step);
			},
			
			__readonly() {
				return this.readonly || this.disabled;
			},
			
			__points() {
				const width = this._size.slider.width - this._size.slider.height, count = Math.floor((this.__max - this.__min) / this.__step) + 1;
				
				return Object.fromEntries(Array.from(Array(count)).map((item, i) => [this.getFixedValue(this.__min + i * this.__step), {x: width / (count - 1) * i}]));
			}
		},
		
		methods: {
			onResize() {
				this._size.slider = {
					...this._size.slider,
					
					width: this.$refs.slider.offsetWidth,
					height: this.$refs.slider.offsetHeight
				};
			},
			
			onThumbResize() {
				this._size.thumb = {
					...this._size.thumb,
					
					width: this.$refs.thumb.offsetWidth,
					height: this.$refs.thumb.offsetHeight
				};
			},
			
			onMouseWheel(event) {
				const { wheelDelta: delta } = event;
				
				if(!event.defaultPrevented) {
					const values = Object.keys(this.__points).map(this.getFixedValue).sort((a, b) => a - b);
					
					if(!this.__readonly && (delta < 0 ? values.indexOf(this.__value) > 0 : values.indexOf(this.__value) < values.length - 1)) {
						if(event.cancelable) event.preventDefault();
						
						this._value = values[Math.max(0, Math.min(values.length - 1, values.indexOf(this.__value) + (delta > 0 ? 1 : -1)))];
					}
				}
			},
			
			onMouseDown(event) {
				const { pageX: x, pageY: y } = event;
				
				if(event.button === 0) {
					if(event.cancelable && !event.defaultPrevented) event.preventDefault();
					
					const { value } = this.getNearestPoint({x, y});
					
					this._pointer.mouse = {start: {x, y}, active: this.__value !== value};
					
					if(!this.__readonly) this._value = value;
				}
			},
			
			onTouchStart(event) {
				const touches = event.touches ? Array.from(event.touches).map(({ pageX: x, pageY: y }) => ({x, y})) : [];
				
				if(touches.length !== 0) {
					if(event.cancelable && !event.defaultPrevented) event.preventDefault();
					
					const { value } = this.getNearestPoint(touches[0]);
					
					this._pointer.touch = {touches, active: this.__value !== value};
					
					if(!this.__readonly) this._value = value;
				}
			},
			
			onMouseMove(event) {
				const { pageX: x, pageY: y } = event;
				
				if(typeof this._pointer.mouse === 'object') {
					if(!this._pointer.mouse.active) this._pointer.mouse = {
						...this._pointer.mouse,
						
						active: this.getDistance(this._pointer.mouse.start, {x, y}) > 0
					};
					
					if(this._pointer.mouse.active) {
						const { value } = this.getNearestPoint({x, y});
						
						if(!this.__readonly) this._value = value;
					}
				}
			},
			
			onTouchMove(event) {
				const touches = event.touches ? Array.from(event.touches).map(({ pageX: x, pageY: y }) => ({x, y})) : [];
				
				if(typeof this._pointer.touch === 'object') {
					if(!this._pointer.touch.active) this._pointer.touch = {
						...this._pointer.touch,
						
						active: this.getDistance(this._pointer.touch.touches[0], touches[0]) > 0
					};
					
					if(this._pointer.touch.active) {
						const { value } = this.getNearestPoint(touches[0]);
						
						if(!this.__readonly) this._value = value;
					}
				}
			},
			
			onMouseUp(event) {
				if(event.button === 0) {
					if(typeof this._pointer.mouse === 'object' && this._pointer.mouse.active) {
						if(event.cancelable && !event.defaultPrevented) event.preventDefault();
					}
					
					this._pointer.mouse = undefined;
				}
			},
			
			onTouchEnd(event) {
				const touches = event.touches ? Array.from(event.touches).map(({ pageX: x, pageY: y }) => ({x, y})) : [];
				
				if(typeof this._pointer.touch === 'object') {
					if(this._pointer.touch.active && touches.length === 0) {
						if(event.cancelable && !event.defaultPrevented) event.preventDefault();
					}
					
					this._pointer.touch = touches.length === 0 ? undefined : {...this._pointer.touch, touches};
				}
			},
			
			getFixedValue(value) {
				return Number(Math.max(this.__min, Math.min(this.__max, Number(value))).toFixed(String(this.__step).replace(/^\d\.?/, '').length));
			},
			
			getNearestPoint(position) {
				const bounds = this.$refs.slider.getBoundingClientRect(), points = Object.entries(this.__points).map(([ value, item ]) => Object({...item, value}));
				
				const x = position.x - this._size.thumb.width / 2, nearest = points.sort(({ x: x1 }, { x: x2 }) => Math.abs(x1 - x + bounds.x) - Math.abs(x2 - x + bounds.x));
				
				return nearest[0];
			},
			
			getDistance: ({ x: x1, y: y1 }, { x: x2, y: y2 }) => Math.hypot(x1 - x2, y1 - y2)
		},
		
		watch: {
			value(value) {
				this._value = value;
			},
			
			_value(value) {
				if(this.__readonly) {
					this._value = this.value;
					
					return;
				}
				
				this.$emit('value', value);
			}
		},
		
		mounted() {
			this.onResize();
			
			this._observer.resize.slider = new ResizeObserver(this.onResize);
			this._observer.resize.slider.observe(this.$refs.slider);
			
			this._observer.resize.thumb = new ResizeObserver(this.onThumbResize);
			this._observer.resize.thumb.observe(this.$refs.thumb);
			
			this.$refs.slider.addEventListener('wheel', this.onMouseWheel, {passive: false});
			this.$refs.slider.addEventListener('mousewheel', this.onMouseWheel, {passive: false});
			
			this.$refs.slider.addEventListener('mousedown', this.onMouseDown, {passive: false});
			this.$refs.slider.addEventListener('touchstart', this.onTouchStart, {passive: false});
			
			window.addEventListener('mouseup', this.onMouseUp, {passive: false});
			window.addEventListener('mousemove', this.onMouseMove, {passive: false});
			
			window.addEventListener('touchmove', this.onTouchMove, {passive: false});
			window.addEventListener('touchend', this.onTouchEnd, {passive: false});
			window.addEventListener('touchcancel', this.onTouchEnd, {passive: false});
		},
		
		beforeUnmount() {
			if(this._observer.resize.slider !== undefined) this._observer.resize.slider.disconnect();
			if(this._observer.resize.thumb !== undefined) this._observer.resize.thumb.disconnect();
			
			this.$refs.slider.removeEventListener('wheel', this.onMouseWheel);
			this.$refs.slider.removeEventListener('mousewheel', this.onMouseWheel);
			
			this.$refs.slider.removeEventListener('mousedown', this.onMouseDown);
			this.$refs.slider.removeEventListener('touchstart', this.onTouchStart);
			
			window.removeEventListener('mousemove', this.onMouseMove);
			window.removeEventListener('mouseup', this.onMouseUp);
			
			window.removeEventListener('touchmove', this.onTouchMove);
			window.removeEventListener('touchend', this.onTouchEnd);
			window.removeEventListener('touchcancel', this.onTouchEnd);
		}
		
	}
</script>

<style>
	.slider {
		position: relative;
		
		display: flex;
		
		align-items: center;
		
		width: 100%;
		height: 20px;
		
		cursor: ew-resize;
		
		transition: opacity;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.slider.slider-readonly {
		cursor: default;
	}
	
	.slider[disabled] {
		opacity: .6;
	}
	
	.slider[disabled] {
		pointer-events: none;
	}
	
	.slider > .slider-track {
		position: relative;
		
		width: 100%;
		height: 6px;
		
		border-radius: 50px;
		
		border: 1px solid var(--secondary-border-color);
		
		background-color: var(--secondary-background-color);
		
		transition: border-color, background-color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.slider > .slider-thumb {
		aspect-ratio: 1 / 1;
		
		border: 1px solid var(--tertiary-border-color);
		
		background-color: var(--primary-text-color);
		
		border-radius: 50px;
		
		position: absolute;
		
		top: 0;
		left: 0;
		
		height: 100%;
		
		transform: scale(1);
		
		transition: left, transform, background-color, border-color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.slider.slider-moving > .slider-thumb,
	.slider > .slider-thumb:hover {
		background-color: var(--accent-color);
	}
	
	.slider.slider-moving > .slider-thumb {
		transition: transform, background-color, border-color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.slider.slider-moving:not(.slider-readonly) > .slider-thumb,
	.slider:not(.slider-readonly) > .slider-thumb:hover {
		transform: scale(1.25);
	}
</style>
import moment from 'moment';

export default new class {
	
	install = (app) => app?.config?.globalProperties instanceof Object && !(app.config.globalProperties instanceof Array) ? app.config.globalProperties.$utils = this : null;
	
	open = ({ href = document.location.href, target = '_self' } = {}) => window.open(href, target);
	
	getVariableByNumber = (n = 0, v = []) => v[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
	
	getFormattedDate = (date, format) => moment(date instanceof Date ? date : new Date(date)).format(format);
	
	getFormattedNumber = (number = 0, separator = ' ', decimal = '.') => number.toString().replace(/(\d)(?=(\d{3})+(?:[^\d]|\.))/g, '$1?').replace(/\.(\d+)$/, '??$1').replace(/\?+/g, (item) => item.length === 1 ? separator : decimal);
	
	getDateDifference(first, second, format = {}) {
		const formats = {seconds: ['секунда', 'секунды', 'секунд'], minutes: ['минута', 'минуты', 'минут'], hours: ['час', 'часа', 'часов'], days: ['день', 'дня', 'дней'], ...format}, difference = Math.floor((Math.max(first, second) - Math.min(first, second)) / 1000);
		const diff = Object.fromEntries(Object.entries({days: Math.floor(difference / 86400), hours: Math.floor((difference % 86400) / 3600), minutes: Math.floor((difference % 3600) / 60), seconds: Math.floor(difference % 60)}).filter(([ key, value ]) => value !== 0));
		
		return format === 'object' ? diff : Object.entries(diff).map(([ key, value ]) => this.getFormattedNumber(value) + ' ' + (typeof formats[key] === 'object' ? this.getVariableByNumber(value, formats[key]) : formats[key]));
	}
	
	isObject = (object) => object instanceof Object && !this.isArray(object);
	isArray = (object) => object instanceof Array;
	
}
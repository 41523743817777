<template>
	<section class="content-section content-wrapper">
		<h1 class="section-header">
			<p class="text">Расписание звонков</p>
			
			<div class="buttons">
				<vue-button class="button-flat" :loading="$store.state.initialization" @click="$store.dispatch('init')">
					<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
						<path d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
						<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466 "/>
					</svg>
				</vue-button>
			</div>
		</h1>
		
		<div v-if="__timetable.lessons.length > 0" class="timetable">
			<div class="timetable-lessons">
				<div v-for="{ start, end, title }, index in __timetable.lessons.sort(({ index: a }, { index: b }) => a - b)" :key="index" class="timetable-lesson" :class="{'timetable-lesson-active': start <= __timetable.date.getTime() && end > __timetable.date.getTime()}">
					<div class="timetable-lesson-header">
						<span class="timetable-lesson-title" title="Название урока">{{ title }}</span>
						<span class="timetable-lesson-time" title="Время начала и конца урока">{{ $utils.getFormattedDate(start, 'HH:mm') }} – {{ $utils.getFormattedDate(end, 'HH:mm') }}</span>
					</div>
					
					<p v-if="__timetable.date.getTime() >= start && __timetable.date.getTime() < end" class="timetable-lesson-remain" title="Время до конца урока">осталось {{ $utils.getDateDifference(__timetable.date, new Date(end), {seconds: ['секунда', 'секунды', 'секунд'], minutes: ['минуты', 'минуты', 'минут']}).join(', ') }}</p>
					<p v-else class="timetable-lesson-remain" title="Время до начала урока">через {{ $utils.getDateDifference(__timetable.date, new Date(start), {seconds: ['секунду', 'секунды', 'секунд'], minutes: ['минуту', 'минуты', 'минут']}).join(', ') }}</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import VueButton from '@/components/VueButton';
	
	export default {
		name: 'TimetableView',
		
		components: {VueButton},
		
		computed: {
			__timetable() {
				return this.$store.getters.timetable;
			}
		}
	}
</script>

<style>
	.timetable > .timetable-lessons {
		display: flex;
		
		flex-wrap: wrap;
		
		gap: 10px;
	}
	
	.timetable > .timetable-lessons > .timetable-lesson {
		flex-grow: 1;
		
		display: flex;
		
		flex-direction: column;
		
		justify-content: space-between;
		
		padding: 15px 20px;
		
		border-radius: 6px;
		
		background-color: var(--secondary-background-color);
		
		border: 1px solid var(--secondary-border-color);
		
		transition: background-color, border-color, transform;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.timetable > .timetable-lessons > .timetable-lesson:hover,
	.timetable > .timetable-lessons > .timetable-lesson.timetable-lesson-active {
		transform: scale(1.025)
	}
	
	.timetable > .timetable-lessons > .timetable-lesson.timetable-lesson-active {
		border-color: var(--accent-color);
	}
	
	.timetable > .timetable-lessons > .timetable-lesson > .timetable-lesson-header {
		font-weight: bold;
		
		display: flex;
		
		column-gap: 10px;
		
		align-items: center;
		
		word-break: break-word;
	}
	
	.timetable > .timetable-lessons > .timetable-lesson > .timetable-lesson-header > .timetable-lesson-title {
		flex-grow: 1;
	}
	
	.timetable > .timetable-lessons > .timetable-lesson > .timetable-lesson-header > .timetable-lesson-time {
		color: var(--accent-color);
		
		transition: color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.timetable > .timetable-lessons > .timetable-lesson > .timetable-lesson-remain {
		font-weight: bold;
		
		word-break: break-word;
	}
	
	@media (max-width: 768px) {
		.timetable > .timetable-lessons {
			gap: 5px;
		}
		
		.timetable > .timetable-lessons > .timetable-lesson {
			width: 100%;
			
			padding: 7.5px 12.5px;
			
			row-gap: 2.5px;
		}
		
		.timetable > .timetable-lessons > .timetable-lesson > .timetable-lesson-remain {
			font-size: .8rem;
		}
	}
	
	@media (min-width: 768px) and (max-width: 1366px) {
		.timetable > .timetable-lessons {
			gap: 10px;
		}
		
		.timetable > .timetable-lessons > .timetable-lesson {
			width: calc((100% - 10px * 1) / 2);
			
			padding: 12.5px 17.5px;
			
			row-gap: 5px;
		}
		
		.timetable > .timetable-lessons > .timetable-lesson > .timetable-lesson-header {
			font-size: 1.2rem;
		}
	}
	
	@media (min-width: 1366px) {
		.timetable > .timetable-lessons {
			gap: 10px;
		}
		
		.timetable > .timetable-lessons > .timetable-lesson {
			width: calc((100% - 10px * 2) / 3);
			
			padding: 12.5px 17.5px;
			
			row-gap: 5px;
		}
		
		.timetable > .timetable-lessons > .timetable-lesson > .timetable-lesson-header {
			font-size: 1.2rem;
		}
	}
</style>
<template>
	<component :is="is" class="button" :class="{disabled, loading}">
		<div class="button-content">
			<slot />
		</div>
		
		<div class="button-loading"></div>
	</component>
</template>

<script>
	export default {
		name: 'VueButton',
		
		props: {
			disabled: {type: Boolean, required: false},
			loading: {type: Boolean, required: false},
			
			is: {type: String, required: false, default: 'div'}
		}
	}
</script>

<style>
	.button {
		display: inline-flex;
		
		align-items: center;
		
		min-height: 36px;
		
		padding: 10px;
		
		line-height: 1;
		
		border-radius: 4px;
		
		font-size: .85rem;
		font-weight: normal;
		
		cursor: pointer;
		
		position: relative;
		
		user-select: none;
		-webkit-user-select: none;
		
		border: 1px solid var(--tertiary-border-color);
		
		background-color: var(--tertiary-background-color);
		
		color: var(--primary-text-color);
		
		transition: background-color, border-color, color, opacity;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.button:not(.button-flat):hover,
	.button.button-flat.active:hover,
	.button.button-flat:not(.active):active {
		opacity: .8
	}
	
	.button:not(.button-flat):active,
	.button.button-flat.active:active {
		opacity: .6
	}
	
	.button.button-small {
		min-height: 32px;
		
		padding: 8px;
	}
	
	.button.button-wide {
		display: flex;
	}
	
	.button.button-flat {
		background-color: transparent;
		
		border-color: transparent;
		
		color: var(--primary-text-color);
	}
	
	.button.button-flat.active,
	.button.button-flat:hover {
		background-color: var(--tertiary-background-color);
		
		border-color: var(--tertiary-border-color);
	}
	
	.button::before {
		content: '';
		
		top: 50%;
		left: 0;
		
		width: 3px;
		height: 0;
		
		border-radius: 50px;
		
		position: absolute;
		
		transform: translateY(-50%);
		
		background-color: var(--accent-color);
		
		transition: transform, background-color, height;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.button.active::before {height: 50%}
	.button.active:active::before {height: 40%}
	
	.button.button-danger:not(.button-flat),
	.button.button-danger.button-flat:hover {
		background-color: var(--danger-color);
		border-color: var(--danger-color);
		
		color: var(--danger-contrast-color);
	}
	
	.button.button-danger::before {
		background-color: var(--danger-contrast-color);
	}
	
	.button.button-success:not(.button-flat),
	.button.button-success.button-flat:hover {
		background-color: var(--success-color);
		border-color: var(--success-color);
		
		color: var(--success-contrast-color);
	}
	
	.button.button-success::before {
		background-color: var(--success-contrast-color);
	}
	
	.button.button-warning:not(.button-flat),
	.button.button-warning.button-flat:hover {
		background-color: var(--warning-color);
		border-color: var(--warning-color);
		
		color: var(--warning-contrast-color);
	}
	
	.button.button-warning::before {
		background-color: var(--warning-contrast-color);
	}
	
	.button.disabled,
	.button.loading {
		pointer-events: none;
	}
	
	.button.disabled {
		opacity: .6;
	}
	
	.button > .button-content {
		display: flex;
		
		flex-wrap: wrap;
		flex-grow: 1;
		
		align-items: center;
		
		gap: 5px 10px;
		
		opacity: 1;
		
		transition: opacity;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.button > .button-content > .icon {
		width: 16px;
		height: 16px;
		
		flex-shrink: 0;
	}
	
	.button > .button-content > .text {
		flex-grow: 1;
		
		padding: 0 10px;
	}
	
	.button.button-small > .button-content > .text {
		padding: 0 5px;
	}
	
	.button > .button-content > span {
		color: var(--accent-color);
		
		font-weight: bold;
		
		transition: color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.button.button-small > .button-content {
		column-gap: 8px;
	}
	
	.button.button-small > .button-content > .icon {
		width: 14px;
		height: 14px;
	}
	
	.button.loading > .button-content {
		opacity: 0;
	}
	
	.button > .button-loading {
		position: absolute;
		
		width: 20px;
		height: 20px;
		
		top: calc(50% - 20px / 2);
		left: calc(50% - 20px / 2);
		
		border: 2px solid var(--primary-text-color);
		border-bottom-color: transparent;
		
		border-radius: 50px;
		
		animation: 1s button-loading linear infinite;
		
		pointer-events: none;
		
		opacity: 0;
		
		transition: border-color, opacity;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.button.loading > .button-loading {
		opacity: 1;
	}
	
	@keyframes button-loading {
		from {transform: rotate(0deg)}
		to {transform: rotate(360deg)}
	}
</style>
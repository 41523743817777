<template>
	<section class="content-section content-wrapper carousel-wrapper">
		<vue-carousel v-if="files.length > 0" :images="files" ref="carousel" :loading="$store.state.initialization" @preview="({ hash }) => $router.push({query: {...$route.query, image: hash}})" @refresh="!$store.state.initialization && $store.dispatch('init')" />
		
		<div v-if="files.length === 0" class="large-banner">
			<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
				<path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2M9.5 7h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5m3 0h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5M2 10.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5" />
			</svg>
			
			<p v-if="!$store.state.initialization" class="text">В данный момент нет актуального расписания уроков! <span class="action" @click="$store.dispatch('init')">Обновить</span></p>
			<p v-else class="text">Пожалуйста, подождите! Идёт загрузка актуального расписания уроков!</p>
		</div>
	</section>
</template>

<script>
	import VueCarousel from '@/components/VueCarousel';
	
	export default {
		name: 'LessonsView',
		
		components: {VueCarousel},
		
		computed: {
			files() {
				return this.$store.state.files.filter(({ type }) => type === 'lessons').map(({ base64, url, hash }) => ({src: base64 || url, hash}));
			}
		}
	}
</script>

<style>
	.carousel-wrapper {
		height: 1024px;
		
		min-height: 250px;
		max-height: 100%;;
	}
	
	.large-banner {
		display: flex;
		
		flex-direction: column;
		
		align-items: center;
		justify-content: center;
		
		height: 500px;
		
		row-gap: 75px;
		
		padding: 0 20px;
		
		background-color: var(--secondary-background-color);
		
		border: 1px solid var(--secondary-border-color);
		
		border-radius: 6px;
		
		transition: background-color, border-color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.large-banner > .icon {
		width: 125px;
		height: 125px;
	}
	
	.large-banner > .text {
		text-align: center;
		
		font-weight: bold;
		font-size: .9rem;
		
		text-transform: uppercase;
		
		max-width: 350px;
	}
	
	.large-banner > .text > .action {
		cursor: pointer;
		
		color: var(--accent-color);
		
		user-select: none;
		
		transition: opacity, color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.large-banner > .text > .action:hover {opacity: .8}
	.large-banner > .text > .action:active {opacity: .6}
</style>
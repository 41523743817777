<template>
	<div class="input" :class="__class" :disabled="disabled ? '' : undefined">
		<div class="input-primary-content" @click="!$event.defaultPrevented && $refs.input.focus()">
			<slot name="primary-left-content"></slot>
			
			<div class="primary-input-container">
				<input v-bind="__inputAttrs" v-model="_value" ref="input">
			</div>
			
			<slot name="primary-right-content"></slot>
		</div>
		<div class="input-loading-content">
			<slot name="loading-content"></slot>
			
			<svg v-if="!$slots['loading-content']" class="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
				<path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0" />
				<path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z" />
			</svg>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'VueInput',
		
		emits: [
			'value',
			'change',
			'input',
			'cut',
			'copy',
			'paste',
			'focus',
			'blur',
			'invalid'
		],
		
		props: {
			type: {type: String, required: false},
			
			value: {type: [String, Number], required: false},
			placeholder: {type: String, required: false},
			
			name: {type: String, required: false},
			
			pattern: {type: String, required: false},
			
			minlength: {type: [String, Number], required: false},
			maxlength: {type: [String, Number], required: false},
			
			min: {type: [String, Number], required: false},
			max: {type: [String, Number], required: false},
			step: {type: [String, Number], required: false},
			
			autocomplete: {type: String, required: false, default: 'off'},
			
			required: {type: [Boolean, String, Number], required: false},
			
			readonly: {type: [Boolean, String, Number], required: false},
			disabled: {type: [Boolean, String, Number], required: false},
			
			loading: {type: [Boolean, String, Number], required: false},
			
			visible: {type: [Boolean, String, Number], required: false}
		},
		
		data() {
			return {
				_value: this.value,
				
				_state: {
					focused: false,
					invalid: false
				}
			};
		},
		
		computed: {
			__class() {
				return {
					'input-readonly': this.__readonly,
					
					'input-loading': this.loading,
					
					'input-focused': this._state.focused,
					'input-invalid': this._state.invalid
				};
			},
			
			__readonly() {
				return this.readonly || this.disabled || this.loading;
			},
			
			__inputAttrs() {
				const { type, placeholder, name, pattern, autocomplete, required, readonly, disabled } = this.$props;
				
				const options = {type, placeholder, name, pattern, autocomplete, required, readonly, disabled};
				
				if(type === 'password') {
					options.type = this.visible ? 'text' : 'password';
				}
				
				if(['text', 'search', 'url', 'tel', 'email', 'password'].includes(type)) {
					options.minlength = this.$props.minlength;
					options.maxlength = this.$props.maxlength;
				}
				
				if(['number', 'range'].includes(type)) {
					options.type = 'number';
					
					options.min = this.$props.min;
					options.max = this.$props.max;
					options.step = this.$props.step;
				}
				
				return options;
			},
			
			__sliderAttrs() {
				const { value, min, max, step } = this.$props;
				
				const options = {value, min, max, step};
				
				return options;
			}
		},
		
		methods: {
			focus(...args) {
				return this.$refs.input.focus(...args);
			},
			
			blur(...args) {
				return this.$refs.input.blur(...args);
			},
			
			onChange(event) {
				if(this.__readonly) {
					if(event.cancelable && !event.defaultPrevented) event.preventDefault();
					
					return;
				}
				
				this.$emit('change', event);
			},
			
			onInput(event) {
				if(this.__readonly) {
					if(event.cancelable && !event.defaultPrevented) event.preventDefault();
					
					return;
				}
				
				this.$emit('input', event);
				
				this._state = {
					...this._state,
					
					invalid: !event.target.checkValidity(),
					empty: event.target.value.length === 0
				};
			},
			
			onCut(event) {
				if(this.__readonly) {
					if(event.cancelable && !event.defaultPrevented) event.preventDefault();
					
					return;
				}
				
				this.$emit('cut', event);
			},
			
			onCopy(event) {
				this.$emit('copy', event);
			},
			
			onPaste(event) {
				if(this.__readonly) {
					if(event.cancelable && !event.defaultPrevented) event.preventDefault();
					
					return;
				}
				
				this.$emit('paste', event);
			},
			
			onFocus(event) {
				this._state = {
					...this._state,
					
					focused: document.activeElement === event.target,
					
					invalid: !event.target.checkValidity(),
					empty: event.target.value.length === 0
				};
				
				this.$emit('focus', event);
			},
			
			onBlur(event) {
				this._state = {
					...this._state,
					
					focused: document.activeElement === event.target,
					
					invalid: !event.target.checkValidity(),
					empty: event.target.value.length === 0
				};
				
				this.$emit('blur', event);
			}
		},
		
		watch: {
			value(value) {
				this._value = value;
				
				this.$nextTick(() => {
					this._state = {
						...this._state,
						
						invalid: !this.$refs.input.checkValidity(),
						empty: this.$refs.input.value.length === 0
					};
				});
			},
			
			_value(value) {
				if(this.__readonly) {
					this._value = this.value;
					
					return;
				}
				
				this.$emit('value', value);
			},
			
			'_state.invalid'(invalid) {
				this.$emit('invalid', invalid);
			}
		},
		
		mounted() {
			const input = this.$refs.input;
			
			this._state = {
				...this._state,
				
				invalid: !input.checkValidity(),
				empty: input.value.length === 0
			};
			
			input.addEventListener('change', this.onChange, {passive: false});
			input.addEventListener('input', this.onInput, {passive: false});
			input.addEventListener('cut', this.onCut, {passive: false});
			input.addEventListener('copy', this.onCopy, {passive: false});
			input.addEventListener('paste', this.onPaste, {passive: false});
			input.addEventListener('focus', this.onFocus, {passive: false});
			input.addEventListener('blur', this.onBlur, {passive: false});
		},
		
		beforeUnmount() {
			const input = this.$refs.input;
			
			input.removeEventListener('change', this.onChange);
			input.removeEventListener('input', this.onInput);
			input.removeEventListener('cut', this.onCut);
			input.removeEventListener('copy', this.onCopy);
			input.removeEventListener('paste', this.onPaste);
			input.removeEventListener('focus', this.onFocus);
			input.removeEventListener('blur', this.onBlur);
		}
	}
</script>

<style>
	.input-group {
		display: flex;
		
		column-gap: 10px;
	}
	
	.input-group > .input {
		flex-grow: 1;
	}
	
	.input {
		height: 34px;
		
		width: 100%;
		
		position: relative;
		
		padding: 0 7.5px;
		
		background-color: var(--tertiary-background-color);
		
		border: 1px solid var(--tertiary-border-color);
		
		border-radius: 6px;
		
		transition: background-color, border-color, color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.input[disabled] {
		opacity: .6;
	}
	
	.input[disabled],
	.input.input-loading {
		pointer-events: none;
	}
	
	.input.input-focused,
	.input.input-invalid {
		border-color: var(--accent-color);
	}
	
	.input.input-invalid {
		border-color: var(--danger-color);
	}
	
	.input .icon {
		width: 16px !important;
		height: 16px !important;
	}
	
	.input > .input-primary-content,
	.input > .input-loading-content {
		display: flex;
		
		align-items: center;
		
		width: 100%;
		height: 100%;
		
		column-gap: 10px;
		
		opacity: 1;
		
		transition: opacity;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.input > .input-primary-content {
		opacity: 1;
		
		cursor: text;
	}
	
	.input.input-loading > .input-primary-content {
		opacity: 0;
	}
	
	.input > .input-loading-content {
		position: absolute;
		
		top: 0;
		left: 0;
		
		pointer-events: none;
		
		justify-content: center;
		
		opacity: 0;
	}
	
	.input.input-loading > .input-loading-content {
		opacity: 1;
	}
	
	.input > .input-primary-content > .icon,
	.input > .input-loading-content > .icon {
		flex-shrink: 0;
	}
	
	.input > .input-loading-content > .icon {
		animation: input-loading 1s linear infinite;
	}
	
	.input > .input-primary-content > .text.wide,
	.input > .input-loading-content > .text.wide {
		flex-grow: 1;
	}
	
	.input > .input-primary-content > .primary-input-container {
		flex-grow: 1;
		
		height: 100%;
		width: 100%;
		
		position: relative;
	}
	
	.input > .input-primary-content > .primary-input-container > input,
	.input > .input-primary-content > .primary-input-container > input::placeholder {
		color: var(--primary-text-color);
		
		font-family: var(--font-family);
		font-size: .8rem;
		font-weight: normal;
		
		transition: color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.input > .input-primary-content > .primary-input-container > input {
		padding: 0 2.5px;
		
		height: 100%;
		width: 100%;
		
		border: none;
		
		background: unset;
		
		outline: unset;
	}
	
	.input > .input-primary-content > .primary-input-container > input::placeholder {
		user-select: none;
		
		opacity: .6;
		
		transition: opacity, color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.input > .input-primary-content > .primary-input-container > .slider {
		position: absolute;
		
		height: 15px;
		
		bottom: calc(-16px / 2);
		left: 0;
	}
	
	@keyframes input-loading {
		from {transform: rotate(0deg)}
		to {transform: rotate(360deg)}
	}
</style>
<template>
	<div class="modal">
		<div class="modal-filter" @click="$emit('close')"></div>
		
		<div class="modal-window">
			<div class="modal-header">
				<p class="modal-title">{{ __title }}</p>
				
				<div class="modal-close" @click="$emit('close')">
					<svg class="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
						<path d="M 3.688 3.688 C 3.94 3.438 4.349 3.438 4.599 3.688 L 8 7.091 L 11.401 3.688 C 11.752 3.339 12.351 3.499 12.479 3.978 C 12.538 4.198 12.474 4.437 12.311 4.599 L 8.909 8 L 12.311 11.401 C 12.661 11.751 12.502 12.35 12.023 12.478 C 11.802 12.538 11.565 12.474 11.401 12.311 L 8 8.909 L 4.599 12.311 C 4.249 12.661 3.649 12.501 3.523 12.023 C 3.462 11.801 3.526 11.565 3.688 11.401 L 7.091 8 L 3.688 4.599 C 3.438 4.349 3.438 3.94 3.688 3.688" />
					</svg>
				</div>
			</div>
			
			<div class="modal-body">
				<slot />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'VueModal',
		
		emits: ['close'],
		
		props: {
			title: {type: String, required: true}
		},
		
		computed: {
			__title() {
				return this.title;
			}
		}
	}
</script>

<style>
	.modal {
		position: fixed;
		
		top: 0;
		left: 0;
		
		width: 100%;
		height: 100%;
		
		z-index: 199;
		
		display: flex;
		
		align-items: center;
		justify-content: center;
	}
	
	.modal > .modal-filter {
		position: fixed;
		
		top: 0;
		left: 0;
		
		width: 100%;
		height: 100%;
		
		z-index: 1;
		
		background-color: var(--secondary-background-color);
		
		opacity: .4;
		
		transition: background-color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.modal > .modal-window {
		width: 600px;
		
		max-width: calc(100% - 20px);
		max-height: calc(100% - 20px);
		
		z-index: 2;
		
		overflow: hidden;
		
		display: flex;
		
		flex-direction: column;
		
		border-radius: 6px;
		
		background-color: var(--secondary-background-color);
		
		border: 1px solid var(--secondary-border-color);
		
		transition: background-color, border-color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.modal > .modal-window > .modal-header {
		flex-shrink: 0;
		
		padding: 15px 20px;
		
		display: flex;
		
		align-items: center;
	}
	
	.modal > .modal-window > .modal-header > .modal-title {
		font-weight: bold;
		
		flex-grow: 1;
		
		white-space: nowrap;
		
		text-transform: uppercase;
		
		text-overflow: ellipsis;
		
		overflow: hidden;
	}
	
	.modal > .modal-window > .modal-header > .modal-close {
		flex-shrink: 0;
		
		width: 30px;
		height: 30px;
		
		display: flex;
		
		align-items: center;
		justify-content: center;
		
		cursor: pointer;
		
		color: var(--primary-text-color);
		
		transition: color, opacity;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.modal > .modal-window > .modal-header > .modal-close:hover {
		color: var(--danger-color);
	}
	
	.modal > .modal-window > .modal-header > .modal-close:active {
		opacity: .8;
	}
	
	.modal > .modal-window > .modal-header > .modal-close > .icon {
		width: 20px;
		height: 20px;
	}
	
	.modal > .modal-window > .modal-body {
		flex-grow: 1;
		
		overflow: hidden auto;
		
		padding: 0 20px 25px 20px;
		
		scrollbar-width: thin;
		scrollbar-color: var(--accent-color) transparent;
	}
</style>
<template>
	<div class="image" :class="__class" :style="__styles" ref="image">
		<div class="image-header">
			<div class="image-zoom">
				<p class="text">{{ __zoom.value.toFixed(2) }}%</p>
				
				<vue-slider :value="Math.round(__zoom.value)" :min="__zoom.min" :max="__zoom.max" step="1" @value="(value) => setZoom(value)" />
			</div>
			
			<div class="image-close" @click="$emit('close')">
				<svg class="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
					<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
				</svg>
			</div>
		</div>
	</div>
</template>

<script>
	import VueSlider from '@/components/VueSlider';
	
	export default {
		name: 'VueImage',
		
		emits: ['close'],
		
		props: {
			src: {type: String, required: true}
		},
		
		components: {VueSlider},
		
		data() {
			return {
				_zoom: 0,
				
				_image: {
					src: null,
					
					width: 0,
					height: 0
				},
				
				_size: {
					width: 0,
					height: 0
				},
				
				_position: {
					x: 0,
					y: 0
				},
				
				_pointer: {
					mouse: undefined,
					touch: undefined
				},
				
				_observer: {
					resize: {
						image: undefined
					}
				}
			};
		},
		
		computed: {
			__class() {
				return {
					'image-moving': typeof this._pointer.mouse === 'object' || typeof this._pointer.touch === 'object'
				};
			},
			
			__styles() {
				return {
					'background-image': this._image.src === null ? '' : 'url(\'' + this._image.src + '\')',
					'background-size': this.__size.width + 'px ' + this.__size.height + 'px',
					'background-position': this.__position.x + 'px ' + this.__position.y + 'px'
				};
			},
			
			__zoom() {
				const min = 0, max = 200;
				
				return {value: Math.min(max, Math.max(min, this._zoom)), min, max};
			},
			
			__size() {
				const min = this._image.width * (this._size.height / this._image.height) > this._size.width ? {width: this._size.width, height: this._image.height * (this._size.width / this._image.width)} : {width: this._image.width * (this._size.height / this._image.height), height: this._size.height};
				const max = this._image.width * (this._size.height / this._image.height) > this._size.width ? {width: this._size.width / min.height * this._size.height, height: this._size.height} : {width: this._size.width, height: this._size.height / min.width * this._size.width};
				
				return {width: min.width + (max.width - min.width) / 100 * this.__zoom.value, height: min.height + (max.height - min.height) / 100 * this.__zoom.value};
			},
			
			__position() {
				const min = {x: this.__size.width <= this._size.width ? (this._size.width - this.__size.width) / 2 : this._size.width - this.__size.width, y: this.__size.height <= this._size.height ? (this._size.height - this.__size.height) / 2 : this._size.height - this.__size.height};
				const max = {x: this.__size.width <= this._size.width ? (this._size.width - this.__size.width) / 2 : 0, y: this.__size.height <= this._size.height ? (this._size.height - this.__size.height) / 2 : 0};
				
				return {x: Math.min(max.x, Math.max(min.x, this._position.x)), y: Math.min(max.y, Math.max(min.y, this._position.y)), min, max};
			}
		},
		
		methods: {
			onResize() {
				this._size = {
					...this._size,
					
					width: this.$refs.image.offsetWidth,
					height: this.$refs.image.offsetHeight
				};
			},
			
			onImageMouseWheel(event) {
				if(!event.defaultPrevented) {
					if(event.cancelable) event.preventDefault();
					
					this.setZoom(this.__zoom.value - event.deltaY / 100 * 10);
				}
			},
			
			onImageMouseDown(event) {
				if(event.target !== this.$refs.image && event.target.closest('.image') === this.$refs.image) return;
				
				const { button, pageX: x, pageY: y } = event;
				
				if(button === 0) {
					if(event.cancelable && !event.defaultPrevented) event.preventDefault();
					
					this._pointer.mouse = {start: {x, y}, zoom: this.__zoom, size: this.__size, position: this.__position};
				}
			},
			
			onImageTouchStart(event) {
				const touches = event.touches ? Array.from(event.touches).map(({ pageX: x, pageY: y }) => ({x, y})) : [];
				
				if(event.target !== this.$refs.image && event.target.closest('.image') === this.$refs.image) return;
				
				if(touches.length !== 0) {
					if(event.cancelable && !event.defaultPrevented) event.preventDefault();
					
					this._pointer.touch = {touches, zoom: this.__zoom, size: this.__size, position: this.__position};
				}
			},
			
			onMouseMove(event) {
				const { pageX: x, pageY: y } = event;
				
				if(typeof this._pointer.mouse === 'object') this.setPosition({
					x: this._pointer.mouse.position.x - (this._pointer.mouse.start.x - x),
					y: this._pointer.mouse.position.y - (this._pointer.mouse.start.y - y)
				});
			},
			
			onTouchMove(event) {
				const touches = event.touches ? Array.from(event.touches).map(({ pageX: x, pageY: y }) => ({x, y})) : [];
				
				if(typeof this._pointer.touch === 'object') {
					if(touches.length === 1) this.setPosition({x: this._pointer.touch.position.x - (this._pointer.touch.touches[0].x - touches[0].x), y: this._pointer.touch.position.y - (this._pointer.touch.touches[0].y - touches[0].y)});
					if(touches.length === 2) this.setZoom(this._pointer.touch.zoom.value + (this.getDistance(touches[0], touches[1]) - this.getDistance(this._pointer.touch.touches[0], this._pointer.touch.touches[1])) / 100 * 50);
				}
			},
			
			onMouseUp(event) {
				const { button } = event;
				
				if(typeof this._pointer.mouse === 'object' && button === 0) {
					if(event.cancelable && !event.defaultPrevented) event.preventDefault();
					
					this._pointer.mouse = undefined;
				}
			},
			
			onTouchEnd(event) {
				const touches = event.touches ? Array.from(event.touches).map(({ pageX: x, pageY: y }) => ({x, y})) : [];
				
				if(typeof this._pointer.touch === 'object') {
					if(touches.length === 0) {
						if(event.cancelable && !event.defaultPrevented) event.preventDefault();
						
						this._pointer.touch = undefined;
						
						return;
					}
					
					this._pointer.touch = {...this._pointer.touch, touches, zoom: this.__zoom, size: this.__size, position: this.__position};
				}
			},
			
			onKeyUp(e) {
				if(e.keyCode === 27 && !e.defaultPrevented) {
					if(e.cancelable) e.preventDefault();
					
					this.$emit('close');
				}
			},
			
			setZoom(zoom) {
				const size = this.__size;
				
				this._zoom = Math.max(this.__zoom.min, Math.min(this.__zoom.max, zoom));
				
				if(this.__size.width === size.width && this.__size.height === size.height) return;
				
				this.setPosition({
					x: this.__position.x - (this.__size.width - size.width) / 2,
					y: this.__position.y - (this.__size.height - size.height) / 2
				});
			},
			
			setPosition({ x, y }) {
				this._position = {
					...this._position,
					
					x: Math.max(this.__position.min.x, Math.min(this.__position.max.x, x)),
					y: Math.max(this.__position.min.y, Math.min(this.__position.max.y, y))
				};
			},
			
			getDistance: ({ x: x1, y: y1 }, { x: x2, y: y2 }) => Math.hypot(x1 - x2, y1 - y2),
			
			loadImage() {
				const image = new Image();
				
				image.src = this.src;
				
				image.onload = () => {
					this._image = {
						...this._image,
						
						src: this.src,
						
						width: image.naturalWidth,
						height: image.naturalHeight
					};
				};
			}
		},
		
		watch: {
			src() {
				this.loadImage();
			}
		},
		
		mounted() {
			this._observer.resize.image = new ResizeObserver(this.onResize);
			this._observer.resize.image.observe(this.$refs.image);
			
			this.$refs.image.addEventListener('wheel', this.onImageMouseWheel, {passive: false, capture: true});
			this.$refs.image.addEventListener('mousewheel', this.onImageMouseWheel, {passive: false, capture: true});
			
			this.$refs.image.addEventListener('mousedown', this.onImageMouseDown, {passive: false, capture: true});
			this.$refs.image.addEventListener('touchstart', this.onImageTouchStart, {passive: false, capture: true});
			
			window.addEventListener('mousemove', this.onMouseMove, {passive: true, capture: true});
			window.addEventListener('touchmove', this.onTouchMove, {passive: false, capture: true});
			
			window.addEventListener('mouseup', this.onMouseUp, {passive: false, capture: true});
			window.addEventListener('touchend', this.onTouchEnd, {passive: false, capture: true});
			window.addEventListener('touchcancel', this.onTouchEnd, {passive: false, capture: true});
			
			window.addEventListener('keyup', this.onKeyUp, {passive: false, capture: true});
			
			this.loadImage();
		},
		
		beforeUnmount() {
			if(this._observer.resize.image !== undefined) this._observer.resize.image.disconnect();
			
			this.$refs.image.removeEventListener('wheel', this.onMouseWheel);
			this.$refs.image.removeEventListener('mousewheel', this.onMouseWheel);
			
			this.$refs.image.removeEventListener('mousedown', this.onMouseDown);
			this.$refs.image.removeEventListener('touchstart', this.onTouchStart);
			
			window.removeEventListener('mousemove', this.onMouseMove);
			window.removeEventListener('touchmove', this.onTouchMove);
			
			window.removeEventListener('mouseup', this.onMouseUp);
			window.removeEventListener('touchend', this.onTouchEnd);
			window.removeEventListener('touchcancel', this.onTouchEnd);
			
			window.removeEventListener('keyup', this.onKeyUp);
		}
	}
</script>

<style>
	.image {
		position: fixed;
		
		top: 0;
		left: 0;
		
		width: 100%;
		height: 100%;
		
		z-index: 200;
		
		cursor: grab;
		
		background-color: var(--primary-background-color);
		background-repeat: no-repeat;
		
		transition: background-color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.image.image-moving {
		cursor: grabbing;
	}
	
	.image > .image-header {
		position: absolute;
		
		top: 15px;
		left: 15px;
		
		width: calc(100% - 30px);
		height: 35px;
		
		display: flex;
		
		align-items: center;
		justify-content: flex-end;
		
		column-gap: 10px;
	}
	
	.image > .image-header > .image-zoom {
		width: 350px;
		height: 35px;
		
		max-width: 100%;
		
		display: flex;
		
		align-items: center;
		
		column-gap: 10px;
		
		padding: 0 10px;
		
		border-radius: 50px;
		
		background-color: var(--secondary-background-color);
		
		border: 1px solid var(--secondary-border-color);
		
		transition: background-color, border-color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.image > .image-header > .image-zoom > .text {
		width: 75px;
		
		flex-shrink: 0;
		
		font-size: .85rem;
		font-weight: bold;
		
		text-align: right;
		
		user-select: none;
		-webkit-user-select: none;
	}
	
	.image > .image-header > .image-zoom > .slider {
		flex-grow: 1;
		flex-shrink: 1;
	}
	
	.image > .image-header > .image-close {
		flex-shrink: 0;
		
		height: 35px;
		width: 35px;
		
		cursor: pointer;
		
		border-radius: 50px;
		
		background-color: var(--secondary-background-color);
		
		border: 1px solid var(--secondary-border-color);
		
		transition: background-color, border-color, opacity;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.image > .image-header > .image-close:hover {opacity: .8}
</style>
<template>
	<div class="carousel" >
		<div class="carousel-controls">
			<vue-button v-if="images.length > 0" class="button-flat" @click="$refs.items.scrollBy({left: -$refs.items.clientWidth, behavior: 'smooth'})">
				<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
					<path d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
				</svg>
			</vue-button>
			<vue-button v-if="images.length > 0" class="button-flat" @click="$refs.items.scrollBy({left: $refs.items.clientWidth, behavior: 'smooth'});">
				<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
					<path d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
				</svg>
			</vue-button>
			<vue-button class="button-flat" :loading="loading" @click="$emit('refresh')">
				<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
					<path d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
					<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466 "/>
				</svg>
			</vue-button>
		</div>
		<div class="carousel-items" ref="items">
			<img v-for="item, i in images" :key="i" class="carousel-image" :src="item.src" :alt="'Carousel image №' + (i + 1)" @click="$emit('preview', item)">
		</div>
	</div>
</template>

<script>
	import VueButton from '@/components/VueButton';
	
	export default {
		name: 'VueCarousel',
		
		components: {VueButton},
		
		emits: ['preview', 'refresh'],
		
		props: {
			images: {type: Array[Object], required: true},
			
			loading: {type: Boolean, required: false, default: false}
		}
	}
</script>

<style>
	.carousel {
		position: relative;
		
		background-color: var(--secondary-background-color);
		
		border: 1px solid var(--secondary-border-color);
		
		display: flex;
		
		border-radius: 6px;
		
		height: 100%;
		
		overflow: hidden;
		
		transition: background-color, border-color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.carousel > .carousel-controls {
		display: flex;
		
		align-items: center;
		
		column-gap: 2.5px;
		
		position: absolute;
		
		top: -1px;
		right: -1px;
		
		border-radius: 0 0 0 6px;
		
		padding: 2.5px;
		
		background-color: var(--secondary-background-color);
		
		border: 1px solid var(--secondary-border-color);
		
		transition: background-color, border-color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.carousel > .carousel-items {
		display: inline-flex;
		
		column-gap: 10px;
		
		padding: 10px;
		
		flex-grow: 1;
		
		overflow: auto hidden;
		
		scrollbar-width: thin;
		scrollbar-color: var(--accent-color) transparent;
	}
	
	.carousel > .carousel-items > .carousel-image {
		flex-shrink: 0;
		
		border-radius: 4px;
		
		cursor: pointer;
		
		height: 100%;
		
		vertical-align: middle;
		
		transition: border-color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	@media (max-width: 500px) {
		.carousel > .carousel-items {
			column-gap: 5px;
			
			padding: 5px;
		}
	}
</style>